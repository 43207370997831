import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';

export default class SingleDiscount extends React.Component {
  render() {
    return (
        <tr>
            <td style={{textAlign:'center'}}><p style={{margin: '0', paddingTop: '8px'}}>{this.props.percentage}%</p></td>
            <td style={{textAlign:'center'}}><p style={{margin: '0', paddingTop: '8px'}}>{this.props.price.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}€</p></td>
            <td style={{textAlign:'center'}}>
                <Button ize="sm" variant="danger" 
                onClick={()=> {if(window.confirm('Delete the item?')){return this.props.removeDiscount()}}}>
                    Delete <Trash />
                </Button>
            </td>
        </tr>

    );
  }
}
