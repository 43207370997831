import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { TagFill } from "react-bootstrap-icons";

export default class ProductBrandSelect extends React.Component {
  render() {
    return (
      <Form.Group controlId="productBrand">
        <Form.Label>Product Brand</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <TagFill />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control value={this.props.value} onChange={this.props.handleInputChange} as="select">
          <option value=''>-- Please choose --</option>
    {this.props.brands.map(brand => <option key={brand.id} value={brand.id}>{brand.name}</option>)}
          </Form.Control>
        </InputGroup>
      </Form.Group>
    );
  }
}
