import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import AddUserPage from "./pages/AddUserPage";
import "bootstrap/dist/css/bootstrap.min.css";
import UserListPage from "./pages/UserListPage";
import ClientsListPage from "./pages/ClientsListPage";
import Client from "./pages/Client";
import Banner from "./pages/Banner";
import AddProductPage from "./pages/AddProductPage";
import AddBrand from "./pages/AddBrandPage";
import { Container, Row, Col } from "react-bootstrap";
import Navigation from "./components/general/Navigation";
import ProductListPage from "./pages/ProductListPage";
import { connect } from "react-redux";
import { userFetched, isMobileFetched  } from "./actions";
import LoginPage from "./pages/LoginPage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MailSettingsPage from "./pages/MailSettingsPage";
import OrderListPage from "./pages/OrderListPage";
import SingleOrderPage from "./pages/SingleOrderPage";
import LoadingLogo from './img/LoaderLogo.png';
import AddProducerPage from "./pages/AddProducerPage";
import AddPurposePage from "./pages/AddPurposePage";
import AddTypePage from "./pages/AddTypePage";
import DeliveryCostsPage from "./pages/DeliveryCostsPage";
import OrdersB2C from "./pages/OrdersB2C";
import SingleOrderB2CPage from "./pages/SingleOrderB2CPage";
import SEOOptions from "./pages/SEOOptions";
import Bestsellers from "./pages/Bestsellers";
import MenuEdit from "./pages/MenuEdit";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Articles from "./pages/Articles";
import Article from "./pages/Article";

toast.configure()


const cacheTime = 60 * 5 * 1000;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: cacheTime,
        },
    },
});
class App extends React.Component {

  componentDidMount() {
    this.props.isMobileFetched(window.innerWidth < 900);
    if(!this.props.user.hasOwnProperty('name') && window.localStorage.getItem('loggedUser')){
        this.props.userFetched(JSON.parse(window.localStorage.getItem('loggedUser')).me)
    }
  }

  render() {
    return (
        <QueryClientProvider client={queryClient}>
          <Router>
              <Container style={{ maxWidth: "100%" }}>
                  {this.props.user.hasOwnProperty('name') ? <Row>
                          <Col
                              md="2"
                              style={ this.props.isMobile ? {
                                  background: "#74A0B3",
                                  height: '70px',
                              } : {
                                  minHeight: "100vh",
                                  borderRight: "solid 2px #dee2e6",
                                  background: "#74A0B3",
                              }}
                          >
                              <Navigation />
                          </Col>
                          <Col md="10">
                              <Route path="/users" component={UserListPage} />
                              <Route path="/add-user" component={AddUserPage} />
                              <Route path="/add-product" component={AddProductPage} />
                              <Route path="/add-producer" component={AddProducerPage} />
                              <Route path="/add-brand" component={AddBrand} />
                              <Route path="/purposes" component={AddPurposePage} />
                              <Route path="/types" component={AddTypePage} />
                              <Route path="/products" component={ProductListPage} />
                              <Route path="/mail-settings" component={MailSettingsPage} />
                              <Route path="/orders" component={OrderListPage} />
                              <Route path="/orders-b2c" component={OrdersB2C} />
                              <Route path="/single-order" component={SingleOrderPage} />
                              <Route path="/single-order-b2c" component={SingleOrderB2CPage} />
                              <Route path="/delivery-cost" component={DeliveryCostsPage} />
                              <Route path="/seo" component={SEOOptions} />
                              <Route path="/bestsellers" component={Bestsellers} />
                              <Route path="/menu_edit" component={MenuEdit} />
                              <Route path="/clients" exact component={ClientsListPage} />
                              <Route path="/client/:id" component={Client} />
                              <Route path="/banner_info" component={Banner} />
                              <Route path="/articles" component={Articles} />
                              <Route path="/article/:id" component={Article} />
                          </Col>
                      </Row>
                      : <Route path="/" component={LoginPage} />}
              </Container>
              <div id="loader">
                  <img className="loaderImage" src={LoadingLogo} />
              </div>
          </Router>
        </QueryClientProvider>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isMobile: state.isMobile
  };
};
const mapDispatchToProps = { userFetched, isMobileFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(App);
