import React from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import Request from "../api/Request";
import { toast } from "react-toastify";

export default class SEOOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metaData: [],
    };
  }

  fetchData() {
    Request.getMeta((response) => {
      if (response.success) {
        this.setState({ metaData: response.metaData });
      }
    });
  }

  save() {
    Request.updateMetaDescription(this.state.metaData, (response) => {
      toast(response.success ? "Updated successfully!" : "There was an error.");
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>SEO / Meta data</h2>
            <button onClick={this.save.bind(this)} className='btn btn-success float-right' style={{margin: '10px'}}>Save</button>
            {/* {this.state.homePageMeta.map((meta, id) => ( */}
            {this.state.metaData.map((type, typeId) => {
              return <Table key={typeId} size="sm" striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>{type.name} Meta Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label>
                        Title:
                      <input type="text" value={type.title || ''} onChange={e => {
                          const value = e.target.value;
                          const meta = this.state.metaData;
                          meta[typeId].title = value;
                          this.setState({ metaData: meta })
                        }} />
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Description:<br />
                      <textarea style={{ width: '100%' }}
                        onChange={e => {
                          const value = e.target.value;
                          const meta = this.state.metaData;
                          meta[typeId].description = value;
                          this.setState({ metaData: meta })
                        }}
                      >
                        {type.description}
                      </textarea>
                    </td>
                  </tr>
                </tbody>
              </Table>
            })}

            {/* ))} */}
          </Col>
        </Row>
      </>
    );
  }
}
