import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { PersonFill } from "react-bootstrap-icons";
import SingleUser from "../components/userList/SingleUser";
import Request from "../api/Request";
import { userToEditFetched,  isMobileFetched  } from "../actions";
import {
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";
import { toast } from 'react-toastify';

class UserListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  fetchData() {
    Request.getUsers(response => {
      if (response.success) {
        this.setState({ users: response.users });
      }
    });
  }

  componentDidMount() {
   this.fetchData();
  }

  deleteUser(user) {
    if (window.confirm('Delete (' + user.email + ') user?')) { 
      Request.deleteUser({id: user.id}, (response) => {
        toast(response.success ? 'User deleted successfully!': 'There was an error.');
        if (response.success) {
          this.fetchData();
        }
      })
    };
  }

  addNew() {
    this.props.userToEditFetched({});
    this.props.history.push('/add-user');
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>User List</h2>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Name</th>
                  {this.props.isMobile ? <></> : <th style={{textAlign: 'center'}}>Email</th> }
                  <th style={{textAlign: 'center'}}>Phone</th>
                  <th style={{textAlign: 'center'}}>Country</th>
                  {this.props.isMobile ? <></> :<th style={{textAlign: 'center'}}>Postal Code</th>}
                  {this.props.isMobile ? <></> :<th style={{textAlign: 'center'}}>Discount</th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((user, id) => <SingleUser deleteUserCallback={() => this.deleteUser(user)} user={user} key={id} />)}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col style={{ textAlign: "right" }}>
              <Button onClick={this.addNew.bind(this)} size="lg" variant="success" type="button">
                Add new user <PersonFill />
              </Button>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile
  };
};
const mapDispatchToProps = { userToEditFetched, isMobileFetched  }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListPage));