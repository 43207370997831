import React from "react";
import { Button } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';
import { userToEditFetched , isMobileFetched} from "../../actions";
import {
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";

class SingleUser extends React.Component {
  selectUser() {
    this.props.userToEditFetched(this.props.user);
    this.props.history.push('/add-user');
  }

  render() {
    const tdStyle = {
      textAlign: 'center'
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
      cursor: 'pointer'
    }
    return (
      <tr>
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.name}</p></td>
        {this.props.isMobile ? <></> : <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.email}</p></td>}
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.phone_number}</p></td>
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.country}</p></td>
        {this.props.isMobile ? <></> :<td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.post_code}</p></td>}
        {this.props.isMobile ? <></> : <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>
          {this.props.user.discount
            ? this.props.user.discount.map(element => <>{element.percentage + '% - ' + element.price + '€'}<br /></>)
            : <></>
          }</p></td> }
        <td style={tdStyle}>
          <Button ize="sm" variant="danger"
            onClick={this.props.deleteUserCallback}
          >
            Delete <Trash />
          </Button>
        </td>
      </tr>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    userToEdit: state.userToEdit,
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { userToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleUser));
