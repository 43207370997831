import React, {useEffect, useState} from "react";
import { Row, Col, Table, Button } from "react-bootstrap";

import Request from "../api/Request";
import {
  Link,
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import {useQuery, useQueryClient} from "@tanstack/react-query";
import SingleClient from "../components/userList/SingleClient";
import {Trash} from "react-bootstrap-icons";
import {useHistory} from "react-router-dom/cjs/react-router-dom";


export default function Articles(props){

  const history = useHistory()
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['articles'],
    queryFn: Request.getArticles
  });

  const deleteArticle = async (article) => {
    if(!window.confirm(`Are you sure that you want to remove "(${article.id}) ${article.title}"`)) return;

    const result = await Request.deleteArticle(article.id);
    console.log(result);
    if(!result.success){
      toast(`Error occurred during removing article`);
      return;
    }
    toast(`Article removed (${article.title})`);
    queryClient.invalidateQueries({
      queryKey: ['articles']
    });
  }

  const linkTo = (link) => {
    history.push(link);
  }


  const tdStyle = {
    textAlign: 'center'
  };
  const pStyle = {
    margin: '0',
    paddingTop: '8px',
    cursor: 'pointer'
  }

  if(query.isLoading){
    return '';
  }

  return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>
              Articles ({query.data.articles.length})
              <Link to={"/article/0"} className="btn btn-success btn-md">Add</Link>
            </h2>
            <Table size="sm" striped bordered hover>
              <thead>
              <tr>
                <th style={{textAlign: 'center'}}>#</th>
                <th style={{textAlign: 'center'}}>Name</th>
                <th style={{textAlign: 'center'}}>Link</th>
                <th> </th>
                <th> </th>
              </tr>
              </thead>
              <tbody>
              {query.data.articles.map((article, index) => {
                return (
                    <tr>
                      <td onClick={() => {linkTo("/article/"+article.id)}} style={tdStyle} title={article.id}><p style={pStyle}>{index+1}</p></td>
                      <td onClick={() => {linkTo("/article/"+article.id)}} style={tdStyle}><p style={pStyle}>{article.title}</p></td>
                      <td onClick={() => {linkTo("/article/"+article.id)}} style={tdStyle}><p style={pStyle}>{article.rewrite}</p></td>
                      <td style={tdStyle}>
                        <Link to={"/article/"+article.id} className="btn btn-info btn-sm">Edit</Link>
                      </td>
                      <td style={tdStyle}>
                        <Button size="sm" variant="danger"
                                onClick={() => {deleteArticle(article);}}
                                style={{whiteSpace: "nowrap"}}
                        >
                          Delete <Trash />
                        </Button>
                      </td>

                      {/*{this.props.isMobile ? <></> :<td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.post_code}</p></td>}*/}
                    </tr>
                )
              })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
  );


}
