import React from "react";
import {  Button, Form } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';

export default class SingleDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     favourite: 0,
    };
  }

  componentDidMount(){
    this.setState({favourite: this.props.favourite})
  }

  // setFavouriteCategory() {
  //   this.setState((state) => {
  //     if(this.state.favourite){
  //       state.favourite = false;
  //     }else{
  //     state.favourite = true;
  //     }
  //     return state;
  //   }, ()=> this.props.setFavouriteBrand(this.state.favourite));
  // }

  render() {
    return (
        <tr>
            <td style={{textAlign:'center'}}><p style={{margin: '0', paddingTop: '8px'}}>{this.props.name}</p></td>
            <td style={{textAlign:'center'}}>
                <Button ize="sm" variant="danger" 
                onClick={this.props.removeBrand}>
                    Delete <Trash />
                </Button>
            </td>
        </tr>

    );
  }
}
