import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Server } from "react-bootstrap-icons";

export default class StockQuantityInput extends React.Component {
  render() {
    return (
      <Form.Group controlId="stockQuantity">
        <Form.Label>Stock Quantity</Form.Label>
        <InputGroup>   
          <InputGroup.Prepend>
            <InputGroup.Text><Server /></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            value={this.props.value}
            onChange={this.props.handleInputChange}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
