import React from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ProductNameInput from "../addProduct/ProductNameInput";

export default class SingleType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: null,
      showEditor: false,
        meta_title: props.meta_title
    };
  }

  render() {
    return (
      <>
        <tr>
          <td style={{ textAlign: "center" }}>
            <input
              type="text"
              value={this.props.name || ""}
              // onChange={(e) => {
              //   const value = e.target.value;
              //   const meta = this.state.metaData;
              //   meta[this.props.typeId].title = value;
              //   this.setState({ metaData: meta });
              // }}
              onChange={(event) => {
                let newName = event.target.value;
                this.props.setTypeName(this.props.brand, newName);
                if (this.state.timeout) {
                  clearTimeout(this.state.timeout);
                }
                this.setState({
                  timeout: setTimeout(() => {
                    this.props.typeNameChange(this.props.brand, newName);
                  }, 1000),
                });
              }}
            />
          </td>
          <td style={{ textAlign: "center" }}>
            <Button ize="sm" variant="danger" onClick={this.props.removeBrand}>
              Delete <Trash />
            </Button>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            {this.state.showEditor ? (
              <>
                <CKEditor
                  editor={ClassicEditor}
                  data={this.props.description}
                  onChange={(event, editor) => {
                    if (this.state.timeout) {
                      clearTimeout(this.state.timeout);
                    }
                    this.setState({
                      timeout: setTimeout(() => {
                        this.props.descriptionChange(editor.getData());
                      }, 1000),
                    });
                  }}
                />

                  <h3
                      style={{
                          width: "100%",
                          height: "max-content",
                          fontSize: "18px",
                          textAlign: "center",
                          background: "#73A1B3",
                          color: "#ffffff",
                      }}
                  >
                      Type Meta Title
                  </h3>
                  <ProductNameInput
                      label="Type Meta Title"
                      name={this.state.meta_title}
                      handleInputChange={(event) => {
                          const {value} = event.target;
                          if (this.state.timeout) {
                              clearTimeout(this.state.timeout);
                          }
                          this.setState({
                              timeout: setTimeout(() => {
                                      this.props.metaTitleChange(value);
                                  },
                                  1000),
                              meta_title: value
                          })
                      }}
                  />



                <h3
                  style={{
                    width: "100%",
                    height: "max-content",
                    fontSize: "18px",
                    textAlign: "center",
                    background: "#73A1B3",
                    color: "#ffffff",
                  }}
                >
                  Type Meta Description
                </h3>
                <CKEditor
                  editor={ClassicEditor}
                  data={this.props.meta_description}
                  onChange={(event, editor) => {
                    if (this.state.timeout) {
                      clearTimeout(this.state.timeout);
                    }
                    this.setState({
                      timeout: setTimeout(() => {
                        this.props.metaDescriptionChange(editor.getData());
                      }, 1000),
                    });
                  }}
                />
              </>
            ) : (
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.description }}
                />

                  <h3
                      style={{
                          width: "100%",
                          height: "max-content",
                          fontSize: "18px",
                          textAlign: "center",
                          background: "#73A1B3",
                          color: "#ffffff",
                      }}
                  >
                      Type Meta Title
                  </h3>
                  <div
                      dangerouslySetInnerHTML={{
                          __html: this.props.meta_title,
                      }}
                  />


                <h3
                  style={{
                    width: "100%",
                    height: "max-content",
                    fontSize: "18px",
                    textAlign: "center",
                    background: "#73A1B3",
                    color: "#ffffff",
                  }}
                >
                  Type Meta Description
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.meta_description,
                  }}
                />
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    this.setState({ showEditor: true });
                  }}
                >
                  Show editor for {this.props.name}
                </Button>
              </>
            )}
          </td>
        </tr>
      </>
    );
  }
}
