import React from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import SingleProducer from "../components/addbrand/SingleProducer";
import BrandInput from "../components/addbrand/BrandInput";
import { BagFill, Trash } from "react-bootstrap-icons";
import Request from "../api/Request";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";

export default class AddPurposePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandNameInputValue: "",
      brands: [],
      brandError: true,
    };
  }

  fetchData() {
    Request.getPurposes((response) => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({ brandNameInputValue: value });
  }

  addBrand() {
    if (
      this.state.brandNameInputValue == "" ||
      this.state.brands.some(
        (brand) => brand.name === this.state.brandNameInputValue
      )
    ) {
      this.setState({ brandError: false });
      return;
    }
    this.setState({ brandError: true });

    Request.savePurpose(
      {
        name: this.state.brandNameInputValue,
      },
      (response) => {
        toast(
          response.success
            ? "Purpose saved successfully!"
            : "There was an error."
        );
        this.fetchData();
      }
    );
  }

  removePurpose(brand) {
    if (window.confirm("Delete (" + brand.name + ") purpose?")) {
      Request.deletePurpose({ id: brand.id }, (response) => {
        toast(
          response.success
            ? "Purpose deleted successfully!"
            : "There was an error."
        );
        if (response.success) {
          this.fetchData();
        }
      });
    }
  }

  purposeChange(brand, text) {
    Request.updatePurpose(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }

  setPurposeValue(event, id){
    const{value} = event.target;
    const brands = this.state.brands;

    brands.forEach((purpose)=>{
        if(purpose.id == id){
          purpose.name = value;
        }
    })

  }

  // setFavouriteBrand(brand, favourite){
  //   brand.favourite = favourite;
  //   this.setState((prevState) => ({
  //     brands: {
  //       ...prevState.brands,
  //       favourite: brand.favourite
  //     },
  //   }));
  // }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Purposes</h2>
            <BrandInput
              label={"Purpose"}
              value={this.state.brandNameInputValue}
              handleInputChange={this.handleBrandNameChange.bind(this)}
              onClick={this.addBrand.bind(this)}
              error={this.state.brandError}
            />
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Purpose</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.brands.map((brand, id) => (
                  <tr key={id}>
                    <td style={{ textAlign: "center" }}>
                      <p style={{ margin: "0", paddingTop: "8px" }}>
                        {" "}
                        <Form.Control
                          type="text"
                          value={brand.name}
                          onChange={(event, editor) => {
                            if (this.state.timeout) {
                              clearTimeout(this.state.timeout);
                            }
                            let targetValue = event.target.value;
                            this.setState({
                              timeout: setTimeout(() => {
                                this.purposeChange(brand, targetValue);
                              },
                                1000)
                            });
                            this.setPurposeValue(event, brand.id);
                          }}
                        />
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        ize="sm"
                        variant="danger"
                        onClick={() =>{this.removePurpose(brand)}}
                      >
                        Delete <Trash />
                      </Button>
                    </td>
                  </tr>
                  // <SingleProducer
                  //   name={brand.name}
                  //   removeBrand={() => {
                  //     this.removeBrand(brand);
                  //   }}
                  //   setFavouriteBrand={()=> this.setFavouriteBrand(brand)}
                  // />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}
