import React from "react";
import {
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";
import {orderToEditFetched, isMobileFetched } from "../../actions";
import Request from "../../api/Request";

class SingleOrderB2C extends React.Component {

  selectOrder() {
    this.props.orderToEditFetched(this.props.order);
    this.props.history.push('/single-order-b2c');
  }

  render() {
    const tdStyle = {
      textAlign: 'center',
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
      cursor: 'pointer',
    };
    return (
      <tr>
        <td style={tdStyle}><p onClick={this.selectOrder.bind(this)} style={pStyle}>{this.props.order.number}</p></td>
        <td style={tdStyle}><p onClick={this.selectOrder.bind(this)} style={pStyle}>{this.props.order.delivery.name + ' ' + this.props.order.delivery.surname}</p></td>
        <td style={tdStyle}><p onClick={this.selectOrder.bind(this)} style={pStyle}>{this.props.order.date}</p></td>
        <td style={tdStyle}><p onClick={this.selectOrder.bind(this)} style={pStyle}>{this.props.order.status}</p></td>
        <td style={tdStyle}><p onClick={this.selectOrder.bind(this)} style={pStyle}>{this.props.order.total.toFixed(2)} {this.props.order.currency}</p></td>
      </tr>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { orderToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleOrderB2C));
