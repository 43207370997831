import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";

export default class CurrencySelect extends React.Component {
  render() {
    return (
      <Form.Group controlId="userCountry">
        <Form.Label>Currency</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
            €
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            value={this.props.currency}
            onChange={this.props.handleInputChange}
          >
            <option value="EUR">EUR</option>
            <option value="PLN">PLN</option>
            <option value="USD">USD</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>
    );
  }
}
