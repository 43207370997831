import React from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { TagFill } from "react-bootstrap-icons";

export default class BrandInput extends React.Component {
  render() {
    const errorMesage = !this.props.value ? "Inputs cannot be blank" : "This brand is already exist";   

    return (
      <Row>
        <Col>
          <Form.Group controlId="brandName">
            <Form.Label>{this.props.label ? this.props.label : 'Brand Name'}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><TagFill/></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={this.props.value}
                onChange={this.props.handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Button
            type="button"
            onClick={this.props.onClick}
            variant="success"
            style={{ marginTop: "32px" }}
          >
            Add <PlusCircleFill style={{ marginTop: "-5px" }} />
          </Button>
        </Col>
        <Col md="12">
          <p
            style={{
              color: "red",
              fontSize: "10px",
              marginTop: "-14px",
              display: this.props.error ? "none" : "block"
            }}
          >
            {errorMesage}
          </p>
        </Col>
      </Row>
    );
  }
}
