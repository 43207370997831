import React from "react";
import { Row, Col, Button, Table, Form } from "react-bootstrap";
import Request from "../api/Request";
import { toast } from 'react-toastify';

export default class DeliveryCostsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryCosts: [
      ],
    };
  }

  fetchData() {
    Request.getDeliveryCosts(response => {
      if (response.success) {
        this.setState({ deliveryCosts: response.deliveryCosts });
      }
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  save() {
    Request.updateDeliveryCosts(this.state.deliveryCosts, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }

  render() {
    return (
      <>
      <h1 style={{color:'red'}}>
        NOT USED SECTION!!!
      </h1>
      <h3>Costs are now calculated using server side algorithm</h3>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Delivery Costs</h2>
            <Button onClick={this.save.bind(this)} style={{float: 'right', margin: '10px'}} variant="success">Save</Button>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>Country</th>
                  <th>DHL</th>
                  <th>UPS</th>
                </tr>
              </thead>
              <tbody>
                {this.state.deliveryCosts.map((cost, id) => (
                  <tr key={id}>
                    <td>{cost.country}</td>
                    <td>
                      <Form.Group>
                        <Form.Control
                          min={0}
                          type="number"
                          value={cost.DHL_price}
                          step={0.01}
                          onChange={(event) => {
                            let costs = this.state.deliveryCosts;
                            for (let i = 0; i < costs.length; i++) {
                              if (cost.id == costs[i].id) {
                                if(parseInt(event.target.value) < 0){
                                  costs[i].DHL_price = 0;
                                }else{
                                  costs[i].DHL_price = event.target.value;
                                }
                                break;
                              }
                            }
                            this.setState({deliveryCosts: costs})
                          }}
                        />
                      </Form.Group>

                    </td>
                    <td>
                    <Form.Group>
                        <Form.Control
                          min={0}
                          type="number"
                          value={cost.UPS_price}
                          step={0.01}
                          onChange={(event) => {
                            let costs = this.state.deliveryCosts;
                            for (let i = 0; i < costs.length; i++) {
                              if (cost.id == costs[i].id) {
                                if(parseInt(event.target.value) < 0){
                                  costs[i].UPS_price = 0;
                                }else{
                                  costs[i].UPS_price = event.target.value;
                                }
                                break;
                              }
                            }
                            this.setState({deliveryCosts: costs})
                          }}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}
