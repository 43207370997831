import React from "react";
import { Container, Row, Col, Button, Form, Table, Image } from "react-bootstrap";
import ProductNameInput from "../components/addProduct/ProductNameInput";
import { BagFill } from "react-bootstrap-icons";
import ProductBrandSelect from "../components/addProduct/ProductBrandSelect";
import StockQuantityInput from "../components/addProduct/StockQuantityInput";
import ProductPriceInput from "../components/addProduct/ProductPriceInput";
import { connect } from "react-redux";
import { productToEditFetched, isMobileFetched } from "../actions";
import {
  withRouter
} from 'react-router-dom'
import { toast } from 'react-toastify';
import Request from "../api/Request";
import AddToStockQuantityInput from "../components/addUser/AddToStockQuanityInput";
import ProductTypeSelect from "../components/addProduct/ProductTypeSelect";
import ProductPurposeSelect from "../components/addProduct/ProductPurposeSelect";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
class AddProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      types: [],
      purposes: [],
      product: {
        name: '',
        stock: '',
        price: '',
        brand: '',
        compositions: '',
        product_type_id: [0],
        product_purpose_id: [0],
        purpose_to_show: [0],
        meta_title: '',
        discount_percent: '',
        discount_price: '',
        invoice_name: '',
        gtin: '',
        google_product_category: '',
        hide_google_feed: false,
        position: 0
      },
      addToStockInputValue: 0,
      stockQuantityChanges: [],
      priceChanges: [],
      products: [],
    };
  }

  getStockQuantityChanges() {
    Request.getStockQuantityChanges({ productId: this.state.product.id }, response => {
      if (response.success) {
        this.setState({ stockQuantityChanges: response.stockQuantityChanges })
      }
    });
  }

  componentDidMount() {
    if (this.props.productToEdit && this.props.productToEdit.id) {

      let product = this.props.productToEdit;
      if(product.discount_percent){
        product.discount_price = parseFloat((product.b2c_price * ((100-product.discount_percent)/100)).toFixed(2));
      }

      this.setState({
        product: product
      },
        () => { this.getStockQuantityChanges(); this.getPriceChanges(); }
      );
    }
    Request.getCategories(response => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
    Request.getTypes(response => {
      if (response.success) {
        this.setState({ types: response.categories });
      }
    });
    Request.getPurposes(response => {
      if (response.success) {
        this.setState({ purposes: response.categories });
      }
    });
    Request.getProducts(response => {
      if (response.success) {
        this.setState({
          products: response.products,
          productOptions: response.products.map(product => {
            return { name: product.b2c_name, value: product.id, label: <div><Image style={{ height: '30px' }} src={Request.realProductImage(product.small_image)} />{product.b2c_name}</div> };
          })
        });
      }
    });
  }

  getPriceChanges() {
    if (!this.state.product.id) {
      return;
    }
    Request.getProductPriceChanges(
      { id: this.state.product.id },
      data => this.setState({ priceChanges: data.priceChanges }));
  }

  save() {
    Request.saveProduct(this.state.product, (response) => {
      toast(response.success ? 'Product saved successfully!' : 'There was an error.');
      if (!this.props.productToEdit.id) {
        this.props.history.push('/products');
      } else {
        this.getPriceChanges();
      }
    });
  }

  handleProductNameChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        name: value
      }
    }));
  }

  handleProductB2CNameChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        b2c_name: value
      }
    }));
  }
  handleProductInvoiceNameChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        invoice_name: value
      }
    }));
  }
  handleDiscountPriceChange(event) {
    const { value } = event.target;
    let discount_percent = 0;
    if(value){
      discount_percent = ((value/this.state.product.b2c_price)-1)*-100;
    }
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        discount_price: value,
        discount_percent: discount_percent
      }
    }));
  }
  handleProductMetaTitleChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        meta_title: value
      }
    }));
  }

  handleStockQuantityChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        stock: value
      }
    }));
  }

  handleEURChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        price: parseFloat(value)
      }
    }));
  }

  handleB2CPriceChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        b2c_price: parseFloat(value)
      }
    }));
  }

  handleBrandChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        brand: value
      }
    }));
  }

  handleListingCapacityChange(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        listing_capacity: value
      }
    }));
  }

  handleTypeChange(type, id) {
    const product_type_id = this.state.product.product_type_id;

    product_type_id[id] = parseInt(type);

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        product_type_id: product_type_id
      }
    }));
  }

  addType() {
    const product_type_id = this.state.product.product_type_id;
    product_type_id.push(0);

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        product_type_id: product_type_id
      }
    }));
  }

  deleteType() {
    const product_type_id = this.state.product.product_type_id;
    product_type_id.pop();

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        product_type_id: product_type_id
      }
    }));
  }

  handlePurposeChange(type, id) {
    this.addPurposeToShow();
    const product_purpose_id = this.state.product.product_purpose_id;

    product_purpose_id[id] = parseInt(type);

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        product_purpose_id: product_purpose_id
      }
    }));
  }

  addPurposeToShow(event, type) {
    let groups = window.document.querySelectorAll('.purpose-group');
    let checked = [];
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].querySelector('input').checked) {
        checked.push(parseInt(groups[i].querySelector('select').value));
      }
    }

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        purpose_to_show: checked
      }
    }));
  }

  addPurpose() {
    const product_purpose_id = this.state.product.product_purpose_id;
    product_purpose_id.push(0);

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        product_purpose_id: product_purpose_id
      }
    }));
  }

  deletePurpose() {
    const product_purpose_id = this.state.product.product_purpose_id;
    product_purpose_id.pop();

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        product_purpose_id: product_purpose_id
      }
    }));
  }

  imgChanged(event) {
    let input = event.target;
    if (input.files && input.files[0]) {
      let product = this.state.product;
      product.newImage = input.files[0];
      this.setState({ product: product, changed: true });
      var reader = new FileReader();

      reader.onload = function (e) {
        document.getElementById('productImage').src = e.target.result;
      }

      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  backImgChanged(event) {
    let input = event.target;
    if (input.files && input.files[0]) {
      let product = this.state.product;
      product.newBackImage = input.files[0];
      this.setState({ product: product, changed: true });
      var reader = new FileReader();

      reader.onload = function (e) {
        document.getElementById('productBackImage').src = e.target.result;
      }

      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  handleAddStockQuantityChange(event) {
    const { value } = event.target;
    this.setState({ addToStockInputValue: parseInt(value) },
    );
  }

  addToStockClick() {
    let newQuantity = this.state.product.stock + this.state.addToStockInputValue;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        stock: newQuantity
      }
    }),
      () => Request.setStockQuantity({
        productId: this.state.product.id,
        addedQuantity: this.state.addToStockInputValue,
        endQuantity: this.state.product.stock
      }, (response) => {
        if (response.success) {
          this.getStockQuantityChanges();
        }
      }));
  }

  editorChange(value, name) {
    let newProductState = this.state.product;
    newProductState[name] = value;
    this.setState({ product: newProductState });
  }

  render() {
    const tdStyle = {
      textAlign: 'center',
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
    };
    return (
      <Container>
        <Row>
          <Col md="6" xs="12">
            <div>
              <h2 style={{ marginTop: "50px" }}>{this.props.productToEdit.id ? 'Edit Product' : 'Add Product'}</h2>
              <Form>
                <ProductNameInput
                  name={this.state.product.name}
                  handleInputChange={this.handleProductNameChange.bind(this)}
                />
                <ProductNameInput
                  label="B2C Product Name"
                  name={this.state.product.b2c_name}
                  handleInputChange={this.handleProductB2CNameChange.bind(this)}
                />
                <ProductNameInput
                    label="Invoice Product Name"
                    name={this.state.product.invoice_name}
                    handleInputChange={this.handleProductInvoiceNameChange.bind(this)}
                />
                <ProductNameInput
                  name={this.state.product.alt_tag}
                  label="Alt Tag"
                  handleInputChange={(event) => {
                    const { value } = event.target;
                    this.setState(prevState => ({
                      product: {
                        ...prevState.product,
                        alt_tag: value
                      }
                    }));
                  }}
                />
                <ProductNameInput
                    name={this.state.product.expiry_date}
                    label="Expiry date"
                    handleInputChange={(event) => {
                      const { value } = event.target;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          expiry_date: value
                        }
                      }));
                    }}
                />


                <ProductNameInput
                    name={this.state.product.gtin}
                    label="GTIN"
                    handleInputChange={(event) => {
                      const { value } = event.target;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          gtin: value
                        }
                      }));
                    }}
                />
                <ProductNameInput
                    name={this.state.product.google_product_category}
                    label="Google Product Category [NUMBER]"
                    handleInputChange={(event) => {
                      const { value } = event.target;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          google_product_category: value
                        }
                      }));
                    }}
                />





                <Form.Group controlId="similar-poroducts-group">
                  <Form.Label>Linked products (also interested in)</Form.Label>
                  <Select
                    components={animatedComponents}
                    isMulti
                    isSearchable
                    filterOption={(candidate, input) => {
                      if (input) {
                        return candidate.data.name.toLowerCase().indexOf(input) >= 0;
                      }
                      return true;
                    }}
                    max={5}
                    onChange={(a, e) => {
                      if (!a || (this.state.product.similar_products && this.state.product.similar_products.length > 4 && e.action != "remove-value")) {
                        return true;
                      }
                      let selected = [];
                      a.map(element => {
                        selected.push(element.value);
                      });
                      let p = this.state.product;
                      p.similar_products = selected;
                      this.setState({ product: p });
                    }}
                    value={this.state.productOptions ? this.state.productOptions.map(element => {
                      if (this.state.product.similar_products && this.state.product.similar_products.indexOf(element.value) >= 0) {
                        return element;
                      }
                    }) : []}
                    // isDisabled
                    options={this.state.productOptions} />
                </Form.Group>
                <Form.Group controlId="formBasicRange">
                  <Form.Label>Favourite in month</Form.Label>
                  <Form.Check
                    type='checkbox'
                    checked={this.state.product.favourite_in_month == 1}
                    label={'Favourite in month'}
                    onChange={(event) => {
                      const value = event.target.checked;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          favourite_in_month: value
                        }
                      }));
                    }}
                  />
                </Form.Group>
                <hr />


                <ProductNameInput
                    name={this.state.product.position}
                    label="Listing priority"
                    type="number"
                    handleInputChange={(event) => {
                      const { value } = event.target;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          position: value
                        }
                      }));
                    }}
                />

                <hr/>
                <Form.Group controlId="formBasicRange2">
                  <Form.Label>For Men</Form.Label>
                  <Form.Check
                    type='checkbox'
                    checked={this.state.product.is_for_man == 1}
                    label={'For Men'}
                    onChange={(event) => {
                      const value = event.target.checked;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          is_for_man: value
                        }
                      }));
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicRange3">
                  <Form.Label>Verified</Form.Label>
                  <Form.Check
                    type='checkbox'
                    checked={this.state.product.verified == 1}
                    label={'Verified'}
                    onChange={(event) => {
                      const value = event.target.checked;
                      this.setState(prevState => ({
                        product: {
                          ...prevState.product,
                          verified: value
                        }
                      }));
                    }}
                  />
                </Form.Group>


                <Form.Group controlId="formBasicRangeFeed">
                  <Form.Label>Hide on Google Products Feed</Form.Label>
                  <Form.Check
                      type='checkbox'
                      checked={this.state.product.hide_google_feed}
                      label={'Hide on Google Products Feed'}
                      onChange={(event) => {
                        const value = event.target.checked;
                        console.log(value);
                        this.setState(prevState => ({
                          product: {
                            ...prevState.product,
                            hide_google_feed: !!value
                          }
                        }));
                      }}
                  />
                </Form.Group>
                <hr />


                <img style={{ maxHeight: '200px', maxWidth: '100%', cursor: 'pointer' }} id="productImage" src={this.state.product.small_image ? Request.realProductImage(this.state.product.small_image) : 'https://dummyimage.com/600x400/000/fff&text=!'} onClick={() => document.getElementById('imageFile').click()} />
                <input onChange={(e) => this.imgChanged(e)} id="imageFile" style={{ display: 'none' }} type="file" />
                <ProductBrandSelect
                  value={this.state.product.brand}
                  brands={this.state.brands}
                  handleInputChange={this.handleBrandChange.bind(this)}
                />

                <div style={{ border: '1px solid grey', padding: '20px' }}>
                  <Form.Label>Product Purpose</Form.Label>
                  {this.state.product.product_purpose_id ? this.state.product.product_purpose_id.map((type, id) => {
                    return (
                      <ProductPurposeSelect
                        key={id}
                        value={type}
                        brands={this.state.purposes}
                        handleInputChange={(e) => this.handlePurposeChange(e.target.value, id)}
                        addPurposeToShow={(event) => this.addPurposeToShow(event, type)}
                        checked={this.state.product.purpose_to_show ? this.state.product.purpose_to_show.includes(type) : []}
                      />
                    )
                  }) : <></>}


                  <Row style={{ marginBottom: '30px' }}>
                    <Col><Button onClick={this.addPurpose.bind(this)}>Add Purpose</Button></Col>
                    {this.state.product.product_purpose_id.length > 1 ?
                      <Col><Button variant="danger" onClick={this.deletePurpose.bind(this)}>Delete Last Purpose</Button></Col>
                      : <></>
                    }
                  </Row>
                </div>
                <div style={{ border: '1px solid grey', padding: '20px', marginTop: '10px' }}>
                  <Form.Label>Product Type</Form.Label>
                  {this.state.product.product_type_id ? this.state.product.product_type_id.map((type, id) => {
                    return (
                      <ProductTypeSelect
                        key={id}
                        value={type}
                        brands={this.state.types}
                        handleInputChange={(e) => this.handleTypeChange(e.target.value, id)}
                      />
                    )
                  }) : <></>}
                  <Row style={{ marginBottom: '30px' }}>
                    <Col><Button onClick={this.addType.bind(this)}>Add Type</Button></Col>
                    {this.state.product.product_type_id.length > 1 ?
                      <Col><Button variant="danger" onClick={this.deleteType.bind(this)}>Delete Last Type</Button></Col>
                      : <></>
                    }
                  </Row>
                </div>
                {this.props.productToEdit.id ?
                  <AddToStockQuantityInput
                    value={this.state.product.stock}
                    addToStockValue={this.state.addToStockInputValue}
                    handleInputChange={this.handleAddStockQuantityChange.bind(this)}
                    buttonClick={this.addToStockClick.bind(this)}
                  /> :
                  <StockQuantityInput
                    value={this.state.product.stock}
                    handleInputChange={this.handleStockQuantityChange.bind(this)}
                  />
                }
              </Form>
            </div>
          </Col>
          <Col md="6" xs="12">
            {this.props.isMobile ? <></> : <h4 style={{ marginTop: "96px" }}></h4>}
            <ProductPriceInput
              eur={this.state.product.price}
              handleEURChange={this.handleEURChange.bind(this)}
            />
            <ProductPriceInput
              eur={this.state.product.b2c_price}
              label={'B2C Price EUR'}
              handleEURChange={this.handleB2CPriceChange.bind(this)}
            />

            <ProductPriceInput
                label="Discount price"
                eur={this.state.product.discount_price}
                handleEURChange={this.handleDiscountPriceChange.bind(this)}
            />

            <p style={{ marginTop: '296px' }}>Product back image</p>
            <img style={{ maxHeight: '200px', maxWidth: '100%', cursor: 'pointer' }} id="productBackImage" src={this.state.product.back_image ? Request.realProductImage(this.state.product.back_image) : 'https://dummyimage.com/600x400/000/fff&text=!'} onClick={() => document.getElementById('backImageFile').click()} />
            <input onChange={(e) => this.backImgChanged(e)} id="backImageFile" style={{ display: 'none' }} type="file" />
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>Compositions</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.productToEdit.compositions}
                onChange={(event, editor) => {
                  this.editorChange(editor.getData(), 'compositions');
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Contains</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.productToEdit.contains}
                onChange={(event, editor) => {
                  this.editorChange(editor.getData(), 'contains');
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Capacity</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.productToEdit.capacity}
                onChange={(event, editor) => {
                  this.editorChange(editor.getData(), 'capacity');
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Listing Capacity</Form.Label>
              <Form.Control
                maxLength="10"
                type="text"
                value={this.state.product.listing_capacity}
                onChange={this.handleListingCapacityChange.bind(this)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.productToEdit.description}
                onChange={(event, editor) => {
                  this.editorChange(editor.getData(), 'description');
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Additional Description</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.productToEdit.additional_description}
                onChange={(event, editor) => {
                  this.editorChange(editor.getData(), 'additional_description');
                }}
              />
            </Form.Group>

            <ProductNameInput
                label="Meta Title"
                name={this.state.product.meta_title}
                handleInputChange={this.handleProductMetaTitleChange.bind(this)}
            />


            <Form.Group>
              <Form.Label>Meta Description</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.productToEdit.meta_description}
                onChange={(event, editor) => {
                  this.editorChange(editor.getData(), 'meta_description');
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "right" }}>
            <Button size="lg" variant="success" type="submit" onClick={this.save.bind(this)}>
              Save product data <BagFill />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <h4>Stock Quantity changes</h4>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>User</th>
                  <th style={{ textAlign: 'center' }}>New Quantity</th>
                  <th style={{ textAlign: 'center' }}>Old Quantity</th>
                  {this.props.isMobile ? <></> : <th style={{ textAlign: 'center' }}>Added Quantity</th>}
                  {this.props.isMobile ? <></> : <th style={{ textAlign: 'center' }}>Date</th>}
                </tr>
              </thead>
              <tbody>
                {this.state.stockQuantityChanges ? this.state.stockQuantityChanges.map((status, id) => (
                  <tr key={id}>
                    <td style={tdStyle}><p style={pStyle}>{status.user}</p></td>
                    <td style={tdStyle}><p style={pStyle}>{status.endQuantity}</p></td>
                    <td style={tdStyle}><p style={pStyle}>{status.beforeQuantity}</p></td>
                    {this.props.isMobile ? <></> : <td style={tdStyle}><p style={pStyle}>{status.addedQuantity}</p></td>}
                    {this.props.isMobile ? <></> : <td style={tdStyle}><p style={pStyle}>{status.date}</p></td>}
                  </tr>
                )) : <></>}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Price changes</h4>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>User</th>
                  <th style={{ textAlign: 'center' }}>New Price</th>
                  <th style={{ textAlign: 'center' }}>Old Price</th>
                  {this.props.isMobile ? <></> : <th style={{ textAlign: 'center' }}>Difference</th>}
                  {this.props.isMobile ? <></> : <th style={{ textAlign: 'center' }}>Date</th>}
                </tr>
              </thead>
              <tbody>
                {this.state.priceChanges ? this.state.priceChanges.map((change, id) => (
                  <tr key={id}>
                    <td style={tdStyle}><p style={pStyle}>{change.user}</p></td>
                    <td style={tdStyle}><p style={pStyle}>{change.new_price}</p></td>
                    <td style={tdStyle}><p style={pStyle}>{change.old_price}</p></td>
                    {this.props.isMobile ? <></> : <td style={tdStyle}><p style={pStyle}>{change.difference}</p></td>}
                    {this.props.isMobile ? <></> : <td style={tdStyle}><p style={pStyle}>{change.date}</p></td>}
                  </tr>
                )) : <></>}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productToEdit: state.productToEdit, // (1)
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { productToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProductPage));
