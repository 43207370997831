import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BagFill } from "react-bootstrap-icons";

export default class ProductNameInput extends React.Component {
  render() {
    return (
      <Form.Group controlId="productName">
        <Form.Label>{this.props.label || 'Product Name'}</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
                {this.props.isPercent
                    ? '%'
                    : <BagFill />
                }

            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type={this.props.type ? this.props.type : 'text'}
            value={this.props.name}
            onChange={this.props.handleInputChange}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
