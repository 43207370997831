import React from "react";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { BagFill } from "react-bootstrap-icons";
import ProductOnList from "../components/productList/ProductOnList";
import { connect } from "react-redux";
import { productToEditFetched, isMobileFetched } from "../actions";
import Request from "../api/Request";
import {
  withRouter
} from 'react-router-dom';
import { toast } from 'react-toastify';

class ProductListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      brands: [],
    };
  }

  fetchData() {
    Request.getProducts(response => {
      if (response.success) {
        this.setState({ products: response.products });
      }
    });
  }

  componentDidMount() {
    this.fetchData();
    Request.getCategories(response => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
  }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({ brandNameInputValue: value });
  }

  addBrand() {
    this.setState({ brandError: true });
    const obj = {
      name: this.state.brandNameInputValue,
      count: 0
    };
    const brand = this.state.brand;

    if (
      this.state.brandNameInputValue == '' ||
      brand.some(
        brand => brand.name === this.state.brandNameInputValue
      )) {
      this.setState({ brandError: false })
    } else {
      brand.push(obj);
      this.setState({ brand: brand, brandNameInputValue: '', });
    }
  }

  removeProduct(product) {
    if (window.confirm('Delete (' + product.name + ') product?')) {
      Request.deleteProduct({ id: product.id }, (response) => {
        toast(response.success ? 'Product deleted successfully!' : 'There was an error.');
        if (response.success) {
          this.fetchData();
        }
      })
    };
  }

  addNew() {
    this.props.productToEditFetched({});
    this.props.history.push('/add-product');
  }

  setProductToEdit(id) {
    this.props.productToEditFetched(id);
  }

  render() {
    return (
      <>
        <Row style={{ marginTop: '10px' }}>
          <Col style={{ textAlign: "right" }}>
            <Button onClick={this.addNew.bind(this)} size="lg" variant="success" type="button">
              Add new product <BagFill />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Products</h2>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Photo</th>
                  <th style={{textAlign: 'center'}}>Product Name</th>
                  <th style={{textAlign: 'center'}}>Price</th>
                  <th style={{textAlign: 'center'}}>B2C</th>
                  {this.props.isMobile ? <></> : <th style={{textAlign: 'center'}}>Brand</th>}
                  {this.props.isMobile ? <></> : <th style={{textAlign: 'center'}}>Stock quantity</th>}
                  <th style={{textAlign: 'center'}}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.products ? this.state.products.map((product, id) => (
                  <ProductOnList
                    key={id}
                    product={product}
                    brands={this.state.brands}
                    deleteProductCallback={() => {
                      this.removeProduct(product);
                    }}
                  />
                )) : <></>}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col style={{ textAlign: "right" }}>
            <Button onClick={this.addNew.bind(this)} size="lg" variant="success" type="button">
              Add new product <BagFill />
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { productToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListPage));