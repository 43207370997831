import React from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import BrandInput from "../components/addbrand/BrandInput";
import { BagFill } from "react-bootstrap-icons";
import Request from "../api/Request";
import { toast } from 'react-toastify';
import SingleType from "../components/addbrand/SingleType";

export default class AddTypePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandNameInputValue: "",
      brands: [
      ],
      brandError: true,
    };
  }

  fetchData() {
    Request.getTypes(response => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
  }

  componentDidMount() {
   this.fetchData();
  }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({ brandNameInputValue: value });
  }

  addBrand() {
    if (
      this.state.brandNameInputValue == '' ||
      this.state.brands.some(
        brand => brand.name === this.state.brandNameInputValue
      )) {
      this.setState({ brandError: false });
      return;
    }
    this.setState({ brandError: true });

    Request.saveType({
      name: this.state.brandNameInputValue,
    }, (response) => {
        toast(response.success ? 'Type saved successfully!': 'There was an error.');
        this.fetchData();
    });
  }

  removeBrand(brand) {
    if (window.confirm('Delete (' + brand.name + ') type?')) {
      Request.deleteType({id: brand.id}, (response) => {
        toast(response.success ? 'Type deleted successfully!': 'There was an error.');
        if (response.success) {
          this.fetchData();
        }
      })
    };
  }

  setTypeName(brand, text){
    let brands = this.state.brands;

    brands.map(currentBrand => {
      if(currentBrand.id == brand.id){
        currentBrand.name = text;
      }
    });

    this.setState({brands: brands});
  }

  typeNameChange(brand, text){


    Request.updateTypeName(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }

  descriptionChange(brand, text) {
    Request.updateTypeDescription(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }

  metaTitleChange(brand, text) {
    Request.updateTypeMetaTitle(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }
  metaDescriptionChange(brand, text) {
    Request.updateTypeMetaDescription(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }


  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Types</h2>
            <BrandInput
              label={'Type'}
              value={this.state.brandNameInputValue}
              handleInputChange={this.handleBrandNameChange.bind(this)}
              onClick={this.addBrand.bind(this)}
              error={this.state.brandError}
            />
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.brands.map((brand, id) => (
                  <SingleType
                    brand={brand}
                    name={brand.name}
                    typeNameChange={(brand, text) => {this.typeNameChange(brand, text)}}
                    setTypeName={(brand, text) => {this.setTypeName(brand, text)}}
                    removeBrand={() => {
                      this.removeBrand(brand);
                    }}
                    typeId={brand.id}
                    description={brand.description}
                    descriptionChange={(text) => {this.descriptionChange(brand, text)}}
                    meta_title={brand.meta_title}
                    metaTitleChange={(text) => {this.metaTitleChange(brand, text)}}
                    meta_description={brand.meta_description}
                    metaDescriptionChange={(text) => {this.metaDescriptionChange(brand, text)}}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}
