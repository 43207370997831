import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { LockFill } from "react-bootstrap-icons";


export default class PasswordInput extends React.Component {
  render() {
    return (
      <Form.Group controlId="userPassword">
        <Form.Label >Password</Form.Label>
        <InputGroup>   
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1"><LockFill /></InputGroup.Text>
          </InputGroup.Prepend>
        <Form.Control type="password" 
        value={this.props.password} 
        onChange={this.props.handleInputChange} 
        />
        </InputGroup>
      </Form.Group>
    );
  }
}

