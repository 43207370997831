import React from "react";
import { Button, Image } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';
import {
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";
import { productToEditFetched, isMobileFetched } from "../../actions";
import Request from "../../api/Request";

class OrderedProductOnList extends React.Component {

  render() {
    const tdStyle = {
      textAlign: 'center',
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
    };
    return (
      <tr>
        <td style={tdStyle}><p style={pStyle}><Image style={{ width: '100px' }} src={Request.realProductImage(this.props.product.small_image || this.props.product.image)} /></p></td>
        <td style={tdStyle}><p style={pStyle}>{this.props.product.product_name}</p></td>
        <td style={tdStyle}><p style={pStyle}>{this.props.product.price.toFixed(2)} {this.props.currency}</p></td>
        {this.props.isMobile ? <></> : <td style={tdStyle}><p style={pStyle}>{this.props.product.brand}</p></td>}
        <td style={tdStyle}><p style={pStyle}>{this.props.product.quantity}</p></td>
      </tr>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { productToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderedProductOnList));