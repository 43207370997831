import React from "react";
import { Form } from "react-bootstrap";

export default class StatusChange extends React.Component {

  render() {
    return <Form>
    <Form.Group>
      <Form.Control value={this.props.value} onChange={this.props.change} as="select">
        <option value="New Order">New Order</option>
        <option value="Payed">Payed</option>
        <option value="In Preparation">In Preparation</option>
        <option value="Sent">Sent</option>
        <option value="Delivered">Delivered</option>
        <option value="Payment Error">Payment Error</option>
        <option value="Cancelled">Cancelled</option>
      </Form.Control>
    </Form.Group>

  </Form>;
  }
}
