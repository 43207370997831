import React from "react";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import { Server } from "react-bootstrap-icons";
import { PlusCircleFill } from "react-bootstrap-icons";

export default class AddToStockQuantityInput extends React.Component {
  render() {
    return (
      <Row>
        <Col>
        <p>Stock Quantity <strong>{this.props.value}</strong></p>
          <Form.Group controlId="stockQuantity">
            <Form.Label>
              Add products to stock
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <Server />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                value={this.props.addToStockInputValue}
                onChange={this.props.handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Button
            type="button"
            onClick={this.props.buttonClick}
            variant="success"
            style={{ marginTop: "72px" }}
          >
            Add <PlusCircleFill style={{ marginTop: "-5px" }} />
          </Button>
        </Col>
      </Row>
    );
  }
}
