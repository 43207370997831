import React from "react";
import {Row, Col, Table, Button} from "react-bootstrap";
import Request from "../api/Request";
import {userToEditFetched, isMobileFetched} from "../actions";
import {
    withRouter
} from 'react-router-dom'
import {connect} from "react-redux";
import SingleOrderB2C from "../components/orderList/SingleOrderB2C";

class Client extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                id: props.match.params.id
            },
            orders: []
        };

    }

    fetchData() {
        Request.getClientById(this.state.user.id, response => {
            if (response.success) {
                this.setState({user: response.user});
            }
        });
        Request.getOrdersByClientId(this.state.user.id, response => {
            if (response.success) {
                this.setState({ orders: response.orders });
            }
        })
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {

        const paidOrders = [];
        const newOrders = [];
        const cancelledOrders = [];
        let paidTotal = 0;
        this.state.orders.forEach((order) => {
            if(order.isPaid){
                paidOrders.push(order);
                paidTotal += order.total;
            }
            if(order.isNew){
                newOrders.push(order);
            }
            if(order.isCancelled){
                cancelledOrders.push(order);
            }
        })

        return (
            <>
                <Row>
                    <Col>
                        <h4 style={{marginTop: "0px"}}>Client Data</h4>
                        <Table size="sm" striped bordered hover>
                            <tbody>

                            <tr>
                                <th>Email</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.email}
                                </th>
                            </tr>

                            <tr>
                                <th>Name</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.name}
                                </th>
                            </tr>

                            <tr>
                                <th>Surname</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.surname}
                                </th>
                            </tr>

                            <tr>
                                <th>Phone</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.country_number} {this.state.user.phone_number}
                                </th>
                            </tr>

                            <tr>
                                <th>Country</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.country}
                                </th>
                            </tr>

                            <tr>
                                <th>City</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.city}
                                </th>
                            </tr>

                            <tr>
                                <th>Postcode</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.post_code}
                                </th>
                            </tr>

                            <tr>
                                <th>Address</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.address}
                                </th>
                            </tr>

                            <tr>
                                <th>Address 2</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.extra_address}
                                </th>
                            </tr>

                            <tr>
                                <th>Company</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.company_name}
                                </th>
                            </tr>

                            <tr>
                                <th>VAT number</th>
                                <th style={{fontWeight: "300"}}>
                                    {this.state.user.vat_number}
                                </th>
                            </tr>


                            </tbody>
                        </Table>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <h4 style={{marginTop: "0px"}}>Orders summary</h4>
                        <Table size="sm" striped bordered hover>
                            <tbody>

                            <tr>
                                <th>Paid Total</th>
                                <th style={{fontWeight: "300"}}>
                                    {paidTotal} EUR
                                </th>
                            </tr>

                            <tr>
                                <th>New orders</th>
                                <th style={{fontWeight: "300"}}>
                                    {newOrders.length}
                                </th>
                            </tr>

                            <tr>
                                <th>Cancelled orders</th>
                                <th style={{fontWeight: "300"}}>
                                    {cancelledOrders.length}
                                </th>
                            </tr>

                            </tbody>
                        </Table>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <h2 style={{ marginTop: "50px" }}>Orders ({this.state.orders.length})</h2>
                        <Table size="sm" striped bordered hover>
                            <thead>
                            <tr>
                                <th style={{textAlign: 'center'}}>Order</th>
                                <th style={{textAlign: 'center'}}>Client</th>
                                <th style={{textAlign: 'center'}}>Date</th>
                                <th style={{textAlign: 'center'}}>Status</th>
                                <th style={{textAlign: 'center'}}>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.orders ? this.state.orders.filter(order => order.delivery.name !=  null && order.delivery.name != '').map((order, id) => (
                                <SingleOrderB2C
                                    key={id}
                                    order={order}
                                />
                            )) : <></>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.isMobile
    };
};
const mapDispatchToProps = {userToEditFetched, isMobileFetched}; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Client));
