export const productToEditFetched = (productToEdit) => ({
  type: "FETCH_PRODUCT_TO_EDIT",
  productToEdit,
});

export const userFetched = (user) => ({
  type: 'FETCH_USER',
  user
})


export const userToEditFetched = (userToEdit) => ({
  type: 'FETCH_USER_TO_EDIT',
  userToEdit
})

export const orderToEditFetched = (orderToEdit) => ({
  type: 'FETCH_ORDER_TO_EDIT',
  orderToEdit
})

export const isMobileFetched = (isMobile) => ({
  type: 'FETCH_IS_MOBILE',
  isMobile
})
