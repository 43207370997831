import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Pen } from "react-bootstrap-icons";

export default class NameInput extends React.Component {
  render() {
    return (
      <Form.Group controlId="userName">
        <Form.Label>Last Name</Form.Label>
        <InputGroup>   
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1"><Pen /></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            value={this.props.lastName}
            onChange={this.props.handleInputChange}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
