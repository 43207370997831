import React from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";

export default class DiscountInput extends React.Component {
  render() {
    const errorMesage =
      !this.props.price || !this.props.percentage
        ? "Inputs cannot be blank"
        : "This value is already exist";

    return (
      <Row>
        <Col>
          <Form.Group controlId="userPercentage">
            <Form.Label>Percentage</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                min={0}
                max={99}
                type="number"
                value={this.props.percentage}
                onChange={this.props.handlePercentageChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="userPrice">
            <Form.Label>Price</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">€</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                min={0}
                type="number"
                value={this.props.price}
                onChange={this.props.handlePriceChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Button
            type="button"
            onClick={this.props.onClick}
            variant="success"
            style={{ marginTop: "32px" }}
          >
            Add <PlusCircleFill style={{ marginTop: "-5px" }} />
          </Button>
        </Col>
        <Col md="12">
          <p
            style={{
              color: "red",
              fontSize: "10px",
              marginTop: "-14px",
              display: this.props.error ? "none" : "block"
            }}
          >
            {errorMesage}
          </p>
        </Col>
      </Row>
    );
  }
}
