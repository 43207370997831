import React from "react";
import { Badge, Button, Image } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';
import {
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";
import { productToEditFetched, isMobileFetched } from "../../actions";
import Request from "../../api/Request";

class ProductOnList extends React.Component {

  b2cErrors(){
    const product = this.props.product

   let badgeVal = parseInt(product.stock > 0 ? 0 : 1) +
            parseInt(product.b2c_price ? 0 : 1) +
            parseInt(product.b2c_price ? 0 : 1) +
            parseInt(product.image ? 0 : 1) +
            parseInt(product.category_id ? 0 : 1) +
            parseInt(product.product_type_id ? 0 : 1) +
            parseInt(product.product_purpose_id ? 0 : 1)

  return badgeVal;

  }

  selectProduct() {
    this.props.productToEditFetched(this.props.product);
    this.props.history.push('/add-product');
  }

  brandName(id) {
    let brand = this.props.brands.filter(b => b.id == id);
    if (brand.length) {
      return brand[0].name;
    }
    return '';
  }

  render() {
    const tdStyle = {
      textAlign: 'center',
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
      cursor: 'pointer',
    };
    return (
      <tr title={this.props.product.id}>
        <td style={tdStyle}><p onClick={this.selectProduct.bind(this)} style={pStyle}><Image style={{ height: '60px' }} src={Request.realProductImage(this.props.product.small_image)} /></p></td>
        <td style={tdStyle}><p onClick={this.selectProduct.bind(this)} style={pStyle}>{this.b2cErrors() != 0  ? <Badge style={{paddingLeft: '6px', paddingRight: '6px'}} pill variant="danger">{this.b2cErrors()}</Badge> : "" }{this.props.product.name}</p></td>
        <td style={tdStyle}><p onClick={this.selectProduct.bind(this)} style={pStyle}>{this.props.product.price}</p></td>
        <td style={tdStyle}><p onClick={this.selectProduct.bind(this)} style={pStyle}>{this.props.product.b2c_price}</p></td>
        {this.props.isMobile ? <></> : <td style={tdStyle}><p onClick={this.selectProduct.bind(this)} style={pStyle}>{this.brandName(this.props.product.brand)}</p></td>}
        {this.props.isMobile ? <></> : <td style={tdStyle}><p onClick={this.selectProduct.bind(this)} style={pStyle}>{this.props.product.stock}</p></td>}
        <td style={tdStyle}>
          <Button ize="sm" variant="danger" style={ this.props.isMobile ? {} : { margin: '12px 20%' }}
            onClick={this.props.deleteProductCallback}>
            Delete <Trash />
          </Button>
        </td>
      </tr>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { productToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductOnList));
