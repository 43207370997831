import React from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import NameInput from "../components/addUser/NameInput";
import LastNameInput from "../components/addUser/LastNameInput";
import EmailInput from "../components/addUser/EmailInput";
import PhoneInput from "../components/addUser/PhoneInput";
import AdressInput from "../components/addUser/AdressInput";
import PasswordInput from "../components/addUser/PasswordInput";
import DiscountInput from "../components/addUser/DiscountInput";
import SingleDiscount from "../components/addUser/SingleDiscount";
import { PersonFill } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { userToEditFetched } from "../actions";
import Request from "../api/Request";
import { toast } from 'react-toastify';
import {
  withRouter
} from 'react-router-dom';
import CompanyNameInput from "../components/addUser/CompanyNameInput";
import NipInput from "../components/addUser/NipInput";
import CurrencySelect from "../components/addUser/CurrencySelect";
import NewsletterSelect from "../components/addUser/NewsletterSelect";

class AddUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameInputValue: "",
      lastNameInputValue: "",
      emailInputValue: "",
      countryNumberInputValue: '',
      phoneInputValue: "",
      adressInputValue: "",
      countryInputValue: "United Kingdom",
      postalCodeInputValue: "",
      passwordInputValue: "",
      percentageInputValue: "",
      priceInputValue: "",
      companyNameInputValue: '',
      nipInputValue: '',
      currencySelectValue: '',
      discount: [],
      discountError: true,
      newsletterSelectValue: true,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.id) {
      let user = this.props.user;
      this.setState({
        nameInputValue: user.name,
        lastNameInputValue: user.last_name,
        emailInputValue: user.email,
        countryNumberInputValue: user.country_number,
        phoneInputValue: user.phone_number,
        adressInputValue: user.address,
        countryInputValue: user.country || "United Kingdom",
        postalCodeInputValue: user.post_code,
        discount: user.discount ? user.discount : [],
        companyNameInputValue: user.company_name,
        nipInputValue: user.NIP,
        currencySelectValue: user.currency || 'EUR',
        newsletterSelectValue: user.newsletter || true,
      });
    }

   
  }

  save() {
    Request.saveUser({
      id: this.props.user.id,
      name: this.state.nameInputValue,
      last_name: this.state.lastNameInputValue,
      email: this.state.emailInputValue,
      country_number: this.state.countryNumberInputValue,
      phone_number: this.state.phoneInputValue,
      address: this.state.adressInputValue,
      country: this.state.countryInputValue,
      post_code: this.state.postalCodeInputValue,
      discount: this.state.discount,
      password: this.state.passwordInputValue,
      companyName: this.state.companyNameInputValue,
      nip: this.state.nipInputValue,
      currency: this.state.currencySelectValue,
    }, (response) => {
        toast(response.success ? 'User saved successfully!': 'There was an error.');
        if (!this.props.user.id) {
          this.props.history.push('/users');
        }
    });
  }

  handleNameChange(event) {
    const { value } = event.target;
    this.setState({ nameInputValue: value });
  }

  handleLastNameChange(event) {
    const { value } = event.target;
    this.setState({ lastNameInputValue: value });
  }

  handleEmailChange(event) {
    const { value } = event.target;
    this.setState({ emailInputValue: value });
  }

  handleCountryNumberChange(event) {
    const { value } = event.target;
    this.setState({ countryNumberInputValue: value });
  }

  handlePhoneChange(event) {
    const { value } = event.target;
    this.setState({ phoneInputValue: value });
  }

  handleAdressChange(event) {
    const { value } = event.target;
    this.setState({ adressInputValue: value });
  }
  handleCountryChange(event) {
    const { value } = event.target;
    this.setState({ countryInputValue: value });
  }
  handlePostalCodeChange(event) {
    const { value } = event.target;
    this.setState({ postalCodeInputValue: value });
  }

  handlePasswordChange(event) {
    const { value } = event.target;
    this.setState({ passwordInputValue: value });
  }

  handleCompanyNameChange(event) {
    const { value } = event.target;
    this.setState({companyNameInputValue: value });
  }

  handleNipChange(event) {
    const { value } = event.target;
    this.setState({ nipInputValue: value });
  }
 handleCurrencyChange(event) {
    const { value } = event.target;
    this.setState({ currencySelectValue: value });
  }

 handleNewsletterChange(event) {
    const { value } = event.target;
    this.setState({ newsletterSelectValue: value });
  }
 

  handlePercentageChange(event) {
    const { value } = event.target;
    if (value > 99) {
      this.setState({ percentageInputValue: 99 });
    } else if(value < 0){
      this.setState({ percentageInputValue: 0 });
    }else{
      this.setState({ percentageInputValue: value });
    }
  }

  handlePriceChange(event) {
    const { value } = event.target;
    if(value < 0 ){
      this.setState({ priceInputValue: 0 });
    }else{
      this.setState({ priceInputValue: value });
    }
  }

  addDiscount() {
    this.setState({ discountError: true });
    const obj = {
      percentage: this.state.percentageInputValue,
      price: this.state.priceInputValue
    };
    const discount = this.state.discount;

    if (
      this.state.percentageInputValue == '' || this.state.priceInputValue == '' ||
      discount.some(
        discount => discount.percentage === this.state.percentageInputValue || discount.price === this.state.priceInputValue
      )) {
      this.setState({ discountError: false })
    } else {
      discount.push(obj);
      this.setState({ discount: discount, percentageInputValue: '', priceInputValue: '' });
    }
  }

  removeDiscount(id) {
    const discount = this.state.discount;
    discount.splice(id, 1);

    this.setState({ discount: discount });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md='6' xs='12'>
            <div>
              <h2 style={{ marginTop: "50px" }}>{this.props.user.name ? 'Edit user' : 'Add new user'}</h2>
              <Form>
                <NameInput
                  name={this.state.nameInputValue}
                  handleInputChange={this.handleNameChange.bind(this)}
                />
                <LastNameInput
                  lastName={this.state.lastNameInputValue}
                  handleInputChange={this.handleLastNameChange.bind(this)}
                />
                <EmailInput
                  email={this.state.emailInputValue}
                  handleInputChange={this.handleEmailChange.bind(this)}
                />
                <PhoneInput
                  phone={this.state.phoneInputValue}
                  handleInputChange={this.handlePhoneChange.bind(this)}
                  countryNumber={this.state.countryNumberInputValue}
                  handleCountryNumberChange={this.handleCountryNumberChange.bind(this)}
                />
                <AdressInput
                  adress={this.state.adressInputValue}
                  country={this.state.countryInputValue}
                  postalCode={this.state.postalCodeInputValue}
                  handleInputChange={this.handleAdressChange.bind(this)}
                  handleCountryChange={this.handleCountryChange.bind(this)}
                  handlePostalCodeChange={this.handlePostalCodeChange.bind(
                    this
                  )}
                />
                <PasswordInput
                  password={this.state.passwordInputValue}
                  handleInputChange={this.handlePasswordChange.bind(this)}
                />
                <CompanyNameInput
                  companyName={this.state.companyNameInputValue}
                  handleInputChange={this.handleCompanyNameChange.bind(this)}
                />
                <NipInput
                  nip={this.state.nipInputValue}
                  handleInputChange={this.handleNipChange.bind(this)}
                />
                <CurrencySelect
                  currency={this.state.currencySelectValue}
                  handleInputChange={this.handleCurrencyChange.bind(this)}
                />
                {/* <NewsletterSelect 
                  newsletter={this.state.newsletterSelectValue}
                  handleInputChange={this.handleNewsletterChange.bind(this)}
                /> */}
              </Form>
            </div>
          </Col>
          <Col md='6' xs='12'>
            <h4 style={{ marginTop: "100px" }}>Add Discount</h4>
            <DiscountInput
              percentage={this.state.percentageInputValue}
              price={this.state.priceInputValue}
              handlePercentageChange={this.handlePercentageChange.bind(this)}
              handlePriceChange={this.handlePriceChange.bind(this)}
              error={this.state.discountError}
              onClick={this.addDiscount.bind(this)}
            />
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Percentage</th>
                  <th style={{textAlign: 'center'}}>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.discount.map((discount, id) => (
                  <SingleDiscount
                    percentage={discount.percentage}
                    price={discount.price}
                    removeDiscount={() => {
                      this.removeDiscount(id);
                    }}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col style={{ textAlign: "right" }}>
            <Button onClick={this.save.bind(this)} size="lg" variant="success" type="button">
              Save user data <PersonFill />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userToEdit,
  };
};
const mapDispatchToProps = { userToEditFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserPage));