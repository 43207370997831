import React from "react";
import { Row, Col, Button, Form, Table, InputGroup } from "react-bootstrap";
import { EnvelopeFill } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { orderToEditFetched, isMobileFetched, userFetched } from "../actions";
import Request from "../api/Request";
import { withRouter } from "react-router-dom";
import OrderedProductOnList from "../components/singleOrderPage/OrderedProductOnList";
import StatusChange from "../components/singleOrderPage/StatusChange";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';

class SingleOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      brands: [],
      mailSubject: '',
      mailContent: '',
      statusChanges: [],
    };
  }

  fetchData() {
    Request.getMails(response => {
      if (response.success) {
        this.setState({ mailSubject: response.mails.orderStatusChangeMessageSubject, mailContent: response.mails.orderStatusChangeMessageContent });
      }
    });
  }

  getOrderStatusChanges(){
    Request.getOrderStatusChanges({orderId: this.state.order.delivery.id}, response => {
      if (response.success) {
        this.setState({statusChanges: response.orderStatusChanges})
      }
    });
  }

  componentDidMount() {
    this.setState({ order: this.props.orderToEdit },
      ()=> this.getOrderStatusChanges()
      );
    this.fetchData();
    Request.getCategories((response) => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });

    let filteredProducts = [];
    if (this.state.order && this.state.order.number) {
      this.state.order.products.forEach((orderedProduct) => {
        this.state.products.forEach((product) => {
          if (orderedProduct.id == product.id) {
            let newProduct = { ...product };
            newProduct.quantity = orderedProduct.quantity;
            filteredProducts.push(newProduct);
          }
        });
      });
      this.setState({ products: filteredProducts });
    }

    if(this.props.orderToEdit == 0){
      this.props.history.push('/orders');
    }
  }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({ brandNameInputValue: value });
  }

  statusChange(e) {
    let value = e.target.value;
    if (window.confirm('Do you really want to change status?')) {
      Request.setOrderStatus({ orderId: this.state.order.delivery.id, status: value }, response => {
        toast(response.success ? 'Changed successfully!' : 'There was an error.');
        if (response.success) {
          let order = this.state.order;
          order.status = value;
          this.setState({ order: order },
            ()=> this.getOrderStatusChanges()
            );
        }
      });
    }
  }

  inputChange(e, name) {
    let newState = {};
    newState[name] = e.target.value;
    this.setState(newState);
  }

  editorChange(value, name) {
    let newState = {};
    newState[name] = value;
    this.setState(newState);
  }

  send() {
    Request.sendMail({ subject: this.state.mailSubject, content: this.state.mailContent, orderId: this.state.order.delivery.id }, response => {
      toast(response.success ? 'Sent successfully!' : 'There was an error.');
    });

  }

  render() {
    const tdStyle = {
      textAlign: 'center',
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
    };

    if (Object.keys(this.state.order).length < 1) {
      return <></>;
    }
  
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>{this.state.order.number}</h2>
            <h4 style={{ marginTop: "0px" }}>Products</h4>
            <Table size="sm" striped bordered hover>
              <tbody>
                <tr>
                  <th>User</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.name + ' ' +
                      this.state.order.delivery.surname}
                  </th>
                </tr>
                <tr>
                  <th>Company Name</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.company_name}
                  </th>
                </tr>
                <tr>
                  <th>Phone number</th>
                  <th style={{ fontWeight: "300" }}>
                  {this.state.order.delivery.country_number + ' ' + this.state.order.delivery.phone_number}
                  </th>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.email}
                  </th>
                </tr>
                <tr>
                  <th>Address</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.address}
                  </th>
                </tr>
                <tr>
                  <th>Postal Code</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.post_code}
                  </th>
                </tr>
                <tr>
                  <th>Country</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.country}
                  </th>
                </tr>
                <tr>
                  <th>NIP</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.NIP}
                  </th>
                </tr>
                <tr>
                  <th>Delivery</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.delivery}
                  </th>
                </tr>

                <tr>
                  <th>Status</th>
                  <th style={{ fontWeight: "300" }}>
                    <StatusChange value={this.state.order.status} change={this.statusChange.bind(this)} />
                  </th>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            <h4 style={this.props.isMobile ? {} : { marginTop: "96px" }}>Products</h4>
            <Table size="sm" striped bordered hover>
              <tbody>
                <tr>
                  <th>Total</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.total.toFixed(2)} {this.state.order.currency}
                  </th>
                </tr>
                <tr>
                  <th>Discount</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.discount}
                  </th>
                </tr>
                <tr>
                  <th>Stars</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.opinion.stars}
                  </th>
                </tr>
                <tr>
                  <th>Opinion</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.opinion.text}
                  </th>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        {//assssssss
        }
        <Row>
          <Col md={6} xs={12}>
          {this.state.order.delivery.different_invoice_data ? <>
            <h4 style={{ marginTop: "0px" }}>Different Invoice Data</h4>
            <Table size="sm" striped bordered hover>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_invoice_data.name}
                  </th>
                </tr>
                <tr>
                  <th>Company Name</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_invoice_data.company_name}
                  </th>
                </tr>
                <tr>
                  <th>Address</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_invoice_data.address}
                  </th>
                </tr>
                <tr>
                  <th>Postal Code</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_invoice_data.post_code}
                  </th>
                </tr>
                <tr>
                  <th>Country</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_invoice_data.country}
                  </th>
                </tr>
                <tr>
                  <th>NIP</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_invoice_data.nip}
                  </th>
                </tr>
              </tbody>
            </Table>
          </> : <></>}
          </Col>
          <Col  md={6} xs={12}>
          {this.state.order.delivery.different_shipping ? <>
            <h4 style={this.props.isMobile ? {} : { marginTop: "0px" }}>Different Shipping Data</h4>
            <Table size="sm" striped bordered hover>
            <tbody>
                <tr>
                  <th>Name</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_shipping.name}
                  </th>
                </tr>
                <tr>
                  <th>Company Name</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_shipping.company_name}
                  </th>
                </tr>
                <tr>
                  <th>Address</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_shipping.address}
                  </th>
                </tr>
                <tr>
                  <th>Postal Code</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_shipping.post_code}
                  </th>
                </tr>
                <tr>
                  <th>Country</th>
                  <th style={{ fontWeight: "300" }}>
                    {this.state.order.delivery.different_shipping.country}
                  </th>
                </tr>
              </tbody>
            </Table>
            </> : <></>}
          </Col>
        </Row>
{//asdfsdfasdfassfaaaaaaaaaaaaaaaaaaaaaaaaaa
}
        <Row>
          <Col>
            <h4>Products</h4>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Photo</th>
                  <th style={{textAlign: 'center'}}>Product Name</th>
                  <th style={{textAlign: 'center'}}>Price</th>
                  {this.props.isMobile ? <></> : <th style={{textAlign: 'center'}}>Brand</th>}
                  <th style={{textAlign: 'center'}}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {this.state.order.products ? this.state.order.products.map((product, id) => (
                  <OrderedProductOnList
                    key={id}
                    product={product}
                    brands={this.state.brands}
                    currency={this.state.order.currency}
                  />
                )) : <></>}
              </tbody>
            </Table>
          </Col>
        </Row>
        {//Order status chages
        }
         <Row>
         <Col>
           <h4>Order status changes</h4>
           <Table size="sm" striped bordered hover>
             <thead>
               <tr>
                 <th style={{textAlign: 'center'}}>User</th>
                 <th style={{textAlign: 'center'}}>New status</th>
                 <th style={{textAlign: 'center'}}>Old status</th>
                 <th style={{textAlign: 'center'}}>Date</th>
               </tr>
             </thead>
             <tbody>
               {this.state.statusChanges ? this.state.statusChanges.map((status, id) => (
                <tr>
                <td style={tdStyle}><p style={pStyle}>{status.user}</p></td>
                <td style={tdStyle}><p style={pStyle}>{status.newSatus}</p></td>
                <td style={tdStyle}><p style={pStyle}>{status.oldStatus}</p></td>
                <td style={tdStyle}><p style={pStyle}>{status.date}</p></td>
              </tr>
               )) : <></>}
             </tbody>
           </Table>
         </Col>
       </Row>

        {//Order status chages - end
        }
        

        <Row style={{ matginBottom: '50px' }}>
          <Col sm="12">

            <h3>Order status change message</h3>
            <p>
              Available variables: %orderTable% %price% %deliveryTable% %name% %surname% %status%
</p>
            <Form.Group>
              <Form.Label>Subject</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  value={this.state.mailSubject}
                  onChange={(e) => this.inputChange(e, 'mailSubject')}
                />
              </InputGroup>

            </Form.Group>
            <Form.Label>Content</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={this.state.mailContent}
              onChange={(event, editor) => {
                this.editorChange(editor.getData(), 'mailContent');
              }}
            />
          </Col>
          <Col sm="12">
            <Button style={{ float: 'right', marginTop: '20px', marginBottom: '50px' }} size="lg" type="button" onClick={this.send.bind(this)} variant="success"><EnvelopeFill /> Send</Button>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderToEdit: state.orderToEdit,
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { orderToEditFetched, isMobileFetched, userFetched }; // (2)
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleOrderPage)
);
