import React from "react";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { productToEditFetched, isMobileFetched } from "../actions";
import Request from "../api/Request";
import {
  withRouter
} from 'react-router-dom';
import { toast } from 'react-toastify';
import SingleOrder from "../components/orderList/SingleOrder";

class OrderListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
    };
  }

  fetchData() {
    Request.getOrders(response => {
      if (response.success) {
        this.setState({ orders: response.orders });
      }
    });
  }

  componentDidMount() {
    this.fetchData();
    
   }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({brandNameInputValue: value });
  }


  addNew() {
    this.props.productToEditFetched({});
    this.props.history.push('/add-product');
  }

  setProductToEdit(id){
    this.props.productToEditFetched(id);
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Orders</h2>
            <Table size="sm" striped bordered hover>
              <thead> 
                <tr>
                  <th style={{textAlign: 'center'}}>Order</th>
                  <th style={{textAlign: 'center'}}>User</th>
                  <th style={{textAlign: 'center'}}>Date</th>
                  <th style={{textAlign: 'center'}}>Order</th>
                  {this.props.isMobile ? <></> : <th style={{textAlign: 'center'}}>Feedback</th>}
                </tr>
              </thead>
              <tbody>
                {this.state.orders ? this.state.orders.filter(order => order.delivery.name !=  null  && order.delivery.name != '').map((order, id) => (
                  <SingleOrder
                    key={id}
                    order={order}
                  />
                )) : <></>}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { productToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderListPage));