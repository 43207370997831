import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BriefcaseFill } from "react-bootstrap-icons";

export default class CompanyNameInput extends React.Component {
  render() {
    return (
      <Form.Group controlId="companyName">
        <Form.Label>Company Name</Form.Label>
        <InputGroup>   
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1"><BriefcaseFill /></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            value={this.props.companyName}
            onChange={this.props.handleInputChange}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
