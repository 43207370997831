import React from "react";
import {Row, Col, Button, Table, Image, Form} from "react-bootstrap";
import Request from "../api/Request";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { Trash } from 'react-bootstrap-icons';

const animatedComponents = makeAnimated();

export default class Bestsellers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bestsellers: [],
      products: [],
      productOptions: []
    };
  }

  fetchData() {
    Request.getBestsellers((response) => {
      if (response.success) {
        this.setState({ bestsellers: response.bestsellers });
      }
    });
    Request.getProducts(response => {
      if (response.success) {
        this.setState({
          products: response.products,
          productOptions: response.products.map(product => {
            return { name: product.b2c_name, value: product.id, label: <div><Image style={{ height: '30px' }} src={Request.realProductImage(product.small_image)} />{product.b2c_name}</div> };
          })
        });
      }
    });
  }

  save() {
    Request.updateBestsellers({bestsellers: this.state.bestsellers}, (response) => {
      toast(response.success ? "Updated successfully!" : "There was an error.");
    });
  }
  add(){
    const bestsellers = this.state.bestsellers;
    bestsellers.push({
      id: 0,
      unique: Date.now(),
      title: '',
      description: '',
      position: 0,
      product_id: this.state.productOptions[0].value
    });
    this.setState({bestsellers: bestsellers})
  }
  updateBestseller(value, attribute, bestsellerIndex){
    // ev.preventDefault();
    const bestsellers = [...this.state.bestsellers];
    bestsellers[bestsellerIndex][attribute] = value;
    this.setState({bestsellers: bestsellers})
  }
  removeBestsellerByIndex(index){
    const bestsellers = [...this.state.bestsellers];
    bestsellers.splice(index, 1);
    this.setState({bestsellers: bestsellers})
  }

  getProductOptionByID(product_id){
    return this.state.productOptions.find((item) => {
      return item.value === product_id
    })
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Bestsellers</h2>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px'}}>
              <button onClick={this.add.bind(this)} className='btn btn-success'>Add bestseller</button>
              <button onClick={this.save.bind(this)} className='btn btn-success'>Save</button>
            </div>


            <div>
              {this.state.bestsellers.map((bestseller, bestsellerIndex) => {
                return (
                    <div className="BestsellerItem" key={bestseller.unique}>
                      <div className="BestsellerItem__wrapper" style={{width: "50%"}}>
                        <span>Product</span>
                        <Select
                            components={animatedComponents}
                            // isMulti
                            isSearchable
                            filterOption={(candidate, input) => {
                              if (input) {
                                return candidate.data.name.toLowerCase().indexOf(input) >= 0;
                              }
                              return true;
                            }}
                            // max={5}
                            onChange={(product, e) => {
                              const bestsellers = [...this.state.bestsellers];
                              bestsellers[bestsellerIndex].product_id = product.value;
                              this.setState({ bestsellers: bestsellers });
                            }}
                            value={this.getProductOptionByID(bestseller.product_id)}
                            // isDisabled
                            options={this.state.productOptions}
                        />
                      </div>
                      <div className="BestsellerItem__wrapper" style={{width: "50%"}}>
                        <span>Title</span>
                        <input
                            type="text"
                            value={bestseller.title}
                            name="title"
                            className="form-control"
                            onChange={(ev) => {this.updateBestseller(ev.target.value, 'title', bestsellerIndex)}}
                        />
                      </div>
                      <div className="BestsellerItem__wrapper" style={{width: "100%", marginTop: "15px"}}>
                        <span>Description</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data={bestseller.description}
                            onChange={(event, editor) => {
                              this.updateBestseller(editor.getData(), 'description', bestsellerIndex)
                            }}
                        />
                      </div>

                      <button className="BestsellerItem__delete" onClick={() => {this.removeBestsellerByIndex(bestsellerIndex)}}><Trash/></button>
                    </div>
                )
              })}
            </div>


          </Col>
        </Row>
      </>
    );
  }
}
