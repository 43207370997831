import React from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import SingleBrand from "../components/addbrand/SingleBrand";
import BrandInput from "../components/addbrand/BrandInput";
import { BagFill } from "react-bootstrap-icons";
import Request from "../api/Request";
import { toast } from 'react-toastify';

export default class AddBrad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandNameInputValue: "",
      brands: [
      ],
      producers: [
      ],
      brandError: true,
    };
  }

  fetchData() {
    Request.getCategories(response => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
    Request.getProducers(response => {
      this.setState({ producers: response.categories });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({ brandNameInputValue: value });
  }

  addBrand() {
    if (
      this.state.brandNameInputValue == '' ||
      this.state.brands.some(
        brand => brand.name === this.state.brandNameInputValue
      )) {
      this.setState({ brandError: false });
      return;
    }
    this.setState({ brandError: true });

    Request.saveBrand({
      name: this.state.brandNameInputValue,
    }, (response) => {
      toast(response.success ? 'Brand saved successfully!' : 'There was an error.');
      this.fetchData();
    });
  }

  removeBrand(brand) {
    if (window.confirm('Delete (' + brand.name + ') brand?')) {
      Request.deleteBrand({ id: brand.id }, (response) => {
        toast(response.success ? 'Brand deleted successfully!' : response.message ? response.message : 'There was an error.');
        if (response.success) {
          this.fetchData();
        }
      })
    };
  }

  changeProducer(brand, producer_id) {
    Request.changeProducer(brand.id, producer_id, (response) => {
      toast(response.success ? 'Producer changed successfully!' : 'There was an error.');
      if (response.success) {
        this.fetchData();
      }
    });
  }

  descriptionChange(brand, text) {
    Request.updateBrandDescription(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }

  metaTitleChange(brand, text) {
    Request.updateBrandMetaTitle(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }
  metaDescriptionChange(brand, text) {
    Request.updateBrandMetaDescription(brand.id, text, (response) => {
      toast(response.success ? 'Updated successfully!' : 'There was an error.');
    });
  }

  // setFavouriteBrand(brand, favourite){
  //   brand.favourite = favourite;
  //   this.setState((prevState) => ({
  //     brands: {
  //       ...prevState.brands,
  //       favourite: brand.favourite
  //     },
  //   }));
  // }

  favouriteValue(brand) {
    if (brand.favourite == 0) {
      return 1;
    } else {
      return 0;
    }
  }

  setFavouriteBrand(brand) {
    console.log(brand.favourite);
    Request.setFavouriteCategory({ id: brand.id, favourite: this.favouriteValue(brand) }, (response) => {
      toast(response.success ? 'Favourite brand checked successfully!' : 'There was an error.');
      if (response.success) {
        this.fetchData();
      }
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Brands</h2>
            <BrandInput
              value={this.state.brandNameInputValue}
              handleInputChange={this.handleBrandNameChange.bind(this)}
              onClick={this.addBrand.bind(this)}
              error={this.state.brandError}
            />
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: '100px', textAlign: 'center' }}>Favourite</th>
                  <th style={{textAlign: 'center'}}>Brand Name</th>
                  <th style={{textAlign: 'center'}}>Count</th>
                  <th style={{textAlign: 'center'}}>Producer</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.brands.map((brand, id) => (
                  <SingleBrand
                    name={brand.name}
                    count={brand.count}
                    favourite={brand.favourite}
                    producer={brand.category_producer_id}
                    removeBrand={() => {
                      this.removeBrand(brand);
                    }}
                    changeProducer={
                      function (producer_id) {
                        this.changeProducer(brand, producer_id);
                      }.bind(this)}
                    producers={this.state.producers}
                    setFavouriteBrand={() => this.setFavouriteBrand(brand)}
                    description={brand.description}
                    descriptionChange={(text) => {this.descriptionChange(brand, text)}}
                    meta_title={brand.meta_title}
                    metaTitleChange={(text) => {this.metaTitleChange(brand, text)}}
                    meta_description={brand.meta_description}
                    metaDescriptionChange={(text) => {this.metaDescriptionChange(brand, text)}}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}
