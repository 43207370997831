import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { EnvelopeFill } from "react-bootstrap-icons";

export default class EmailInput extends React.Component {
  render() {
    return (
      <Form.Group controlId="userEmail">
        <Form.Label>E-mail address</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <EnvelopeFill />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="email"
            value={this.props.email}
            onChange={this.props.handleInputChange}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
