import React from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import SingleProducer from "../components/addbrand/SingleProducer";
import BrandInput from "../components/addbrand/BrandInput";
import { BagFill } from "react-bootstrap-icons";
import Request from "../api/Request";
import { toast } from 'react-toastify';

export default class AddProducerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandNameInputValue: "",
      brands: [
      ],
      brandError: true,
    };
  }

  fetchData() {
    Request.getProducers(response => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
  }

  componentDidMount() {
   this.fetchData();
  }

  handleBrandNameChange(event) {
    const { value } = event.target;
    this.setState({ brandNameInputValue: value });
  }

  addBrand() {
    if (
      this.state.brandNameInputValue == '' ||
      this.state.brands.some(
        brand => brand.name === this.state.brandNameInputValue
      )) {
      this.setState({ brandError: false });
      return;
    }
    this.setState({ brandError: true });
    
    Request.saveProducer({      
      name: this.state.brandNameInputValue,
    }, (response) => {
        toast(response.success ? 'Producer saved successfully!': 'There was an error.');
        this.fetchData();
    });
  }

  removeBrand(brand) {
    if (window.confirm('Delete (' + brand.name + ') producer?')) { 
      Request.deleteProducer({id: brand.id}, (response) => {
        toast(response.success ? 'Producer deleted successfully!': 'There was an error.');
        if (response.success) {
          this.fetchData();
        }
      })
    };
  }

  // setFavouriteBrand(brand, favourite){
  //   brand.favourite = favourite;
  //   this.setState((prevState) => ({
  //     brands: {
  //       ...prevState.brands,
  //       favourite: brand.favourite
  //     },
  //   }));
  // }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Producers</h2>
            <BrandInput
              label={'Producer Name'}
              value={this.state.brandNameInputValue}
              handleInputChange={this.handleBrandNameChange.bind(this)}
              onClick={this.addBrand.bind(this)}
              error={this.state.brandError}
            />
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Producer Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.brands.map((brand, id) => (
                  <SingleProducer
                    name={brand.name}
                    removeBrand={() => {
                      this.removeBrand(brand);
                    }}
                    setFavouriteBrand={()=> this.setFavouriteBrand(brand)}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}
