import React from "react";
import {Row, Col, Button, Table, Image, Form, InputGroup} from "react-bootstrap";
import Request from "../api/Request";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { Trash } from 'react-bootstrap-icons';

const animatedComponents = makeAnimated();

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner_info: '',
      banner_info_link: ''
    };
  }

  fetchData() {
    Request.getConfiguration('banner_info',(response) => {
      if (response.success) {
        this.setState({ banner_info: response.value });
      }
    });
    Request.getConfiguration('banner_info_link',(response) => {
      if (response.success) {
        this.setState({ banner_info_link: response.value });
      }
    });
  }

  save() {
    Request.updateConfiguration({
      banner_info: this.state.banner_info,
      banner_info_link: this.state.banner_info_link,
    }, (response) => {
      toast(response.success ? "Updated successfully!" : "There was an error.");
    });
  }


  componentDidMount() {
    this.fetchData();
  }

  handleBannerChange(value){
    this.setState({banner_info: value});
  }
  handleBannerLinkChange(value){
    this.setState({banner_info_link: value});
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Banner info</h2>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px'}}>
              <button onClick={this.save.bind(this)} className='btn btn-success'>Save</button>
            </div>


            <div>

              <Form.Group controlId="banner_info">
                <Form.Label>Banner info</Form.Label>
                <Form.Control
                    type="text"
                    value={this.state.banner_info}
                    onChange={(ev) => {this.handleBannerChange(ev.target.value)}}
                />
              </Form.Group>

              <Form.Group controlId="banner_info">
                <Form.Label>Banner link</Form.Label>
                <Form.Control
                    type="text"
                    value={this.state.banner_info_link}
                    onChange={(ev) => {this.handleBannerLinkChange(ev.target.value)}}
                />
              </Form.Group>
            </div>


          </Col>
        </Row>
      </>
    );
  }
}
