import React from 'react';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { EnvelopeFill } from 'react-bootstrap-icons';
import Request from '../api/Request';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default class MailSettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            helloMessageSubject: '',
            helloMessageContent: '',
            newOrderMessageSubject: '',
            newOrderMessageContent: '',
            newOrderMessageAdminSubject: '',
            newOrderMessageAdminContent: '',
            orderStatusChangeMessageSubject: '',
            orderStatusChangeMessageContent: '',
            b2cNewsletterMessageSubject: '',
            b2cNewsletterMessageContent: '',
            b2cRegisterMessageSubject: '',
            b2cRegisterMessageContent: '',
            b2cNewOrderMessageSubject: '',
            b2cNewOrderMessageContent: '',
            b2cNewOrderAdminMessageSubject: '',
            b2cNewOrderAdminMessageContent: '',
            b2cOrderStatusChangeMessageSubject: '',
            b2cOrderStatusChangeMessageContent: '',

            b2cPaymentErrorMessageSubject: '',
            b2cPaymentErrorMessageContent: '',
        };
    }

    componentDidMount() {
        Request.getMails(response => {
            if (response.success) {
                this.setState(response.mails);
            }
        });
    }

    inputChange(e, name) {
        let newState = {};
        newState[name] = e.target.value;
        this.setState(newState);
    }

    editorChange(value, name) {
        let newState = {};
        newState[name] = value;
        this.setState(newState);
    }

    saveAll() {
        Request.saveMails(this.state, response => {
            toast(response.success ? 'Mails saved successfully!' : 'There was an error.');
        });
    }

    render() {
        return <>
            <Row>
                <Col>
                    <h2 style={{ marginTop: "50px" }}>Mail settings</h2>
                </Col>

            </Row>
            <Row>
                <Col sm="12">

                    <h3>Hello message</h3>
                    <p>
                        Available variables: %name% %email% %password%
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.helloMessageSubject}
                                onChange={(e) => this.inputChange(e, 'helloMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.helloMessageContent}
                        onChange={(data) => this.editorChange(data, 'helloMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">

                    <h3>New order message</h3>
                    <p>
                        Available variables: %orderTable% %price% %deliveryTable% %name% %surname% %orderId%
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.newOrderMessageSubject}
                                onChange={(e) => this.inputChange(e, 'newOrderMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.newOrderMessageContent}
                        onChange={(data) => this.editorChange(data, 'newOrderMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">

                    <h3>New order message - ADMIN</h3>
                    <p>
                        Available variables: %orderTable% %price% %deliveryTable% %name% %surname% %orderId%
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.newOrderMessageAdminSubject}
                                onChange={(e) => this.inputChange(e, 'newOrderMessageAdminSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.newOrderMessageAdminContent}
                        onChange={(data) => this.editorChange(data, 'newOrderMessageAdminContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">

                    <h3>Order status change</h3>
                    <p>
                        Available variables: %orderTable% %price% %deliveryTable% %name% %surname% %status%
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.orderStatusChangeMessageSubject}
                                onChange={(e) => this.inputChange(e, 'orderStatusChangeMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.orderStatusChangeMessageContent}
                        onChange={(data) => this.editorChange(data, 'orderStatusChangeMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <h1>B2C</h1>
                <Col sm="12">

                    <h3>B2C Newsletter</h3>
                    <p>
                        Available variables:
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.b2cNewsletterMessageSubject}
                                onChange={(e) => this.inputChange(e, 'b2cNewsletterMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.b2cNewsletterMessageContent}
                        onChange={(data) => this.editorChange(data, 'b2cNewsletterMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">

                    <h3>B2C Register</h3>
                    <p>
                        Available variables: %email% %password%
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.b2cRegisterMessageSubject}
                                onChange={(e) => this.inputChange(e, 'b2cRegisterMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.b2cRegisterMessageContent}
                        onChange={(data) => this.editorChange(data, 'b2cRegisterMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">
                    <h3>B2C New Order</h3>
                    <p>
                        Available variables:
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.b2cNewOrderMessageSubject}
                                onChange={(e) => this.inputChange(e, 'b2cNewOrderMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.b2cNewOrderMessageContent}
                        onChange={(data) => this.editorChange(data, 'b2cNewOrderMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">
                    <h3>B2C New Order ADMIN</h3>
                    <p>
                        Available variables:
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.b2cNewOrderAdminMessageSubject}
                                onChange={(e) => this.inputChange(e, 'b2cNewOrderAdminMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.b2cNewOrderAdminMessageContent}
                        onChange={(data) => this.editorChange(data, 'b2cNewOrderAdminMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">
                    <h3>B2C Order Status Change</h3>
                    <p>
                        Available variables:
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.b2cOrderStatusChangeMessageSubject}
                                onChange={(e) => this.inputChange(e, 'b2cOrderStatusChangeMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.b2cOrderStatusChangeMessageContent}
                        onChange={(data) => this.editorChange(data, 'b2cOrderStatusChangeMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">
                    <h3>B2C Payment Error</h3>
                    <p>
                        Available variables:
                    </p>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><EnvelopeFill /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={this.state.b2cPaymentErrorMessageSubject}
                                onChange={(e) => this.inputChange(e, 'b2cPaymentErrorMessageSubject')}
                            />
                        </InputGroup>

                    </Form.Group>
                    <Form.Label>Content</Form.Label>
                    <SunEditor setContents={this.state.b2cPaymentErrorMessageContent}
                        onChange={(data) => this.editorChange(data, 'b2cPaymentErrorMessageContent')}
                    />
                </Col>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Col sm="12">
                    <Button style={{ float: 'right', marginTop: '20px', marginBottom: '50px' }} size="lg" type="button" onClick={this.saveAll.bind(this)} variant="success"><EnvelopeFill /> Save</Button>
                </Col>
            </Row>
        </>;
    }
}
