// const apiUrl = 'http://localhost:8080/';
let apiUrl = 'https://server.frayawarehouse.com/';
// const apiUrl = 'http://frayaserverlukasz/';
// const apiUrl = 'http://192.168.1.132/';

if (window.location.host === 'localhost:3000' || window.location.host === 'devadmin.frayawarehouse.com') {
    apiUrl = "https://devserver.frayawarehouse.com/";
}
if(window.location.host === 'itadmin.frayawarehouse.com'){
	apiUrl = "https://itserver.frayawarehouse.com/";
}

let bearer = '';
if(window.localStorage.getItem('loggedUser')){
    bearer = JSON.parse(window.localStorage.getItem('loggedUser')).access_token;
}
const get = (callback, url) => {
    return request(callback, {}, url, 'GET');
}

const post = (callback, params, url, authenticated, raw) => {
    return request(callback, params, url, 'POST', authenticated, raw);
}

const request = (callback, params, url, method, authenticated, raw) => {

    let loader = document.getElementById('loader');
    loader.classList.add("loadderActive");

    if (typeof authenticated === 'undefined') {
        authenticated = true;
    }

    let headers = {
        'Accept': 'application/json',
    }

    if (!raw) {
        headers['Content-Type'] = 'application/json';
    }
    const requestParams = {
        method: method,
        headers: headers,
    };
    if (authenticated) {
        requestParams.headers['Authorization'] = 'Bearer ' + bearer;
    }
    if (method == 'POST') {
        requestParams.body = raw ? params : JSON.stringify(params);
    }
    fetch(
        apiUrl + url,
        requestParams
    ).then(response => {
        if (response && response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            // response.json().then(data => console.log(data));
            return [];
        }
    }
    ).then(response => {
        document.getElementById('loader').classList.remove("loadderActive");
        callback(response);
    }).catch(error => console.error('Error:', error));
}

const requestReturn = (params, url, method, authenticated, raw) => {

    let loader = document.getElementById('loader');
    loader.classList.add("loadderActive");

    if (typeof authenticated === 'undefined') {
        authenticated = true;
    }

    let headers = {
        'Accept': 'application/json',
    }

    if (!raw) {
        headers['Content-Type'] = 'application/json';
    }
    const requestParams = {
        method: method,
        headers: headers,
    };
    if (authenticated) {
        requestParams.headers['Authorization'] = 'Bearer ' + bearer;
    }
    if (method == 'POST') {
        requestParams.body = raw ? params : JSON.stringify(params);
    }
    return fetch(
        apiUrl + url,
        requestParams
    ).then(response => {
            if (response && response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                // response.json().then(data => console.log(data));
                return [];
            }
        }
    ).then(response => {
        document.getElementById('loader').classList.remove("loadderActive");
        return response;
    }).catch(error => console.error('Error:', error));
}
const getReturn = (url) => {
    return requestReturn({}, url, 'GET');
}
const postReturn = (params, url, authenticated, raw) => {
    return requestReturn(params, url, 'POST', authenticated, raw);
}

const Request = {
    getMenu(callback) {
        get(response => {
            callback(response);
        }, 'menu/get');
    },
    saveMenu(menu, callback) {
        post(response => {
            callback(response);
        }, {menu: menu}, 'menu/save', true);
    },

    //prepared for reactQuery
    getArticles() {
        return getReturn('articles/get-all');
    },
    getArticle(id) {
        return postReturn({id: id}, 'articles/get');
    },
    deleteArticle(id) {
        return postReturn({id: id}, 'articles/delete');
    },
    saveArticle(article) {
        return postReturn({article: article}, 'articles/save');
    },

    login(email, password, callback) {
        post(response => {
            if (response.success) {
                bearer = response.access_token;
            }
            callback(response);
        }, { email: email, password: password }, 'auth/login', false);
    },
    getUsers(callback) {
        get(response => {
            callback(response);
        }, 'user/get-all');
    },
    getClients(callback) {
        get(response => {
            callback(response);
        }, 'user/get-all-clients');
    },
    getClientById(id, callback) {
        post(response => {
            callback(response);
        }, {id: id}, 'user/get-client-by-id');
    },
    saveUser(data, callback) {
        post(response => {
            callback(response);
        }, data, 'user/save');
    },
    deleteUser(data, callback) {
        post(response => {
            callback(response);
        }, data, 'user/delete');
    },
    deleteClient(data, callback) {
        post(response => {
            callback(response);
        }, data, 'user/delete-client');
    },
    getProducts(callback) {
        get(response => {
            callback(response);
        }, 'product/get-all');
    },
    saveProduct(product, callback) {
        const formData = new FormData();
        formData.append('file', product.newImage);
        formData.append('fileTwo', product.newBackImage);
        formData.append('product', JSON.stringify(product));
        post(response => {
            callback(response);
        }, formData, 'product/save', true, true);
    },
    deleteProduct(data, callback) {
        post(response => {
            callback(response);
        }, data, 'product/delete');
    },
    getProductPriceChanges(data, callback) {
        post(response => {
            callback(response);
        }, data, 'product/get-price-changes');
    },
    getCategories(callback) {
        get(response => {
            callback(response);
        }, 'category/get-all');
    },
    getMenuCategories(callback) {
        get(response => {
            callback(response);
        }, 'category/get-menu-categories');
    },
    getDeliveryCosts(callback) {
        get(response => {
            callback(response);
        }, 'delivery-cost/get-all');
    },
    updateDeliveryCosts(costs, callback) {
        post(response => {
            callback(response);
        }, {deliveryCosts: costs}, 'delivery-cost/update');
    },
    getProducers(callback) {
        get(response => {
            callback(response);
        }, 'producer/get-all');
    },
    getPurposes(callback) {
        get(response => {
            callback(response);
        }, 'purpose/get-all');
    },
    getTypes(callback) {
        get(response => {
            callback(response);
        }, 'type/get-all');
    },
    getMeta(callback) {
        get(response => {
            callback(response);
        }, 'meta-data/get-all');
    },
    getBestsellers(callback) {
        get(response => {
            callback(response);
        }, 'bestsellers/get-all');
    },
    updateBestsellers(data, callback) {
        post(response => {
            callback(response);
        }, data, 'bestsellers/update');
    },
    getConfiguration(key, callback) {
        post(response => {
            callback(response);
        }, {key: key}, 'configuration/get');
    },
    getConfigurationAll(callback) {
        get(response => {
            callback(response);
        }, 'configuration/all');
    },
    updateConfiguration(configuration, callback) {
        post(response => {
            callback(response);
        }, {configuration: configuration}, 'configuration/save');
    },
    saveBrand(data, callback) {
        post(response => {
            callback(response);
        }, data, 'category/save');
    },
    deleteBrand(data, callback) {
        post(response => {
            callback(response);
        }, data, 'category/delete');
    },
    changeProducer(brand_id, producer_id, callback) {
        post(response => {
            callback(response);
        }, {brand_id: brand_id, producer_id: producer_id}, 'category/change-producer');
    },
    updateTypeDescription(id, text, callback) {
        post(response => {
            callback(response);
        }, {id: id, description: text}, 'type/update-description');
    },
    updateTypeName(id, text, callback) {
        post(response => {
            callback(response);
        }, {id: id, name: text}, 'type/update-name');
    },
    updateTypeMetaTitle(id, text, callback) {
        post(response => {
            callback(response);
        }, {id: id, meta_title: text}, 'type/update-meta-title');
    },
    updateTypeMetaDescription(id, text, callback) {
        post(response => {
            callback(response);
        }, {id: id, meta_description: text}, 'type/update-meta-description');
    },
    updateMetaDescription(meta, callback) {
        post(response => {
            callback(response);
        }, {metaData: meta}, 'meta-data/save');
    },
    updatePurpose(id, text, callback) {
        post(response => {
            callback(response);
        }, {id: id, purpose: text}, 'purpose/update-purpose');
    },
    updateBrandDescription(brand_id, text, callback) {
        post(response => {
            callback(response);
        }, {brand_id: brand_id, description: text}, 'category/update-description');
    },
    updateBrandMetaDescription(brand_id, text, callback) {
        post(response => {
            callback(response);
        }, {brand_id: brand_id, meta_description: text}, 'category/update-meta-description');
    },
    updateBrandMetaTitle(brand_id, text, callback) {
        post(response => {
            callback(response);
        }, {brand_id: brand_id, meta_title: text}, 'category/update-meta-title');
    },
    saveProducer(data, callback) {
        post(response => {
            callback(response);
        }, data, 'producer/save');
    },
    deleteProducer(data, callback) {
        post(response => {
            callback(response);
        }, data, 'producer/delete');
    },
    savePurpose(data, callback) {
        post(response => {
            callback(response);
        }, data, 'purpose/save');
    },
    deletePurpose(data, callback) {
        post(response => {
            callback(response);
        }, data, 'purpose/delete');
    },
    saveType(data, callback) {
        post(response => {
            callback(response);
        }, data, 'type/save');
    },
    deleteType(data, callback) {
        post(response => {
            callback(response);
        }, data, 'type/delete');
    },
    //TO DO
    saveMyData(data, callback) {
        post(response => {
            callback(response);
        }, data, 'user/update');
    },
    getOrderHistory(callback) {
        get(response => {
            callback(response);
        }, 'order/history');
    },
    getOrders(callback) {
        get(response => {
            callback(response);
        }, 'order/get-all');
    },
    getOrdersB2C(callback) {
        get(response => {
            callback(response);
        }, 'order/get-all-b2-c');
    },
    getOrdersByClientId(id, callback) {
        post(response => {
            callback(response);
        }, {client_id: id}, 'order/get-all-b2-c');
    },
    createOrder(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/create');
    },
    orderFeedback(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/feedback');
    },
    getMails(callback) {
        get(response => {
            callback(response);
        }, 'mail/get-all');
    },
    saveMails(data, callback) {
        post(response => {
            callback(response);
        }, data, 'mail/save');
    },
    sendMail(data, callback) {
        post(response => {
            callback(response);
        }, data, 'mail/send');
    },
    sendB2CMail(data, callback) {
        post(response => {
            callback(response);
        }, data, 'mail/b2c-send');
    },
    setOrderStatus(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/status-change');
    },
    setB2COrderStatus(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/b2c-status-change');
    },
    realProductImage(url) {
        if (!url) {
            return url;
        }
        if (url.includes('http')) {
            return url;
        }
        return apiUrl + '/uploads/' + url;
    },
    getOrderStatusChanges(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/get-order-status-changes/');
    },
    getB2COrderStatusChanges(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/get-b2c-order-status-changes/');
    },
    setStockQuantity(data, callback) {
        post(response => {
            callback(response);
        }, data, 'product/set-stock-quantity/');
    },
    getStockQuantityChanges(data, callback) {
        post(response => {
            callback(response);
        }, data, 'product/get-stock-quantity-changes/');
    },
    setFavouriteCategory(data, callback) {
        post(response => {
            callback(response);
        }, data, 'category/set-favourite-category/');
    },
};

export default Request;
