import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import Request from "../api/Request";

import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import slugify from "slugify";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {toast} from "react-toastify";


export default function Article(props)
{
    const history = useHistory();
    const id = props.match.params.id;
    const queryKey = `article_${id}`;

    const [cover, setCover] = useState('');
    const [cover4, setCover4] = useState('');

    const queryClient = useQueryClient();
    const query = useQuery({
        queryKey: [queryKey],
        queryFn: () => {
            return Request.getArticle(id);
        },
    });


    const saveMutation = (data) => {
        return Request.saveArticle(data);
    }

    const mutation = useMutation({
        mutationFn: saveMutation,
        onSuccess: data => {

            if(data.error){
                toast(data.error);
                return;
            }

            queryClient.invalidateQueries({
                queryKey: [queryKey]
            });
            queryClient.invalidateQueries({
                queryKey: ['articles']
            })

            const isNew = query.data.id != data.id;
            setCover('');
            setCover4('');

            queryClient.setQueryData([queryKey], data)

            if(isNew){
                history.push('/article/'+data.id);
            }
            toast('Success');
        },
        onError: error => {
            console.log(error);
            toast('Error during save');
        }
    })


    if(query.isLoading) return 'Loading...';
    if(query.isError) return 'Error loading data';
    const changeData = (key, value) => {
        queryClient.setQueryData(
            [queryKey],
            (oldData) => oldData ? {
                ...oldData,
                [key]: value
            } : oldData
        )
    }


    const generateRewrite = () => {
        if(query.data.rewrite != '' && query.data.title != '') return;

        const slug = slugify(query.data.title, {
            lower: true,
            remove: /[*+~.()'"!:@?,]/g
        })

        changeData('rewrite', slug);
    }

    const handleImageChange = (ev) => {
        const [file] = ev.target.files;
        const name = ev.target.getAttribute('name');
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                changeData(name, e.target.result);
            }
            reader.readAsDataURL(file); // convert to base64 string

            switch (name){
                case 'cover': {
                    setCover(URL.createObjectURL(file));
                    break;
                }
                case 'cover4': {
                    setCover4(URL.createObjectURL(file));
                    break;
                }
            }
        }
    }

    const save = () => {
        mutation.mutate(query.data);
    };

    let coverToShow = false;
    if(cover){
        coverToShow = cover;
    }
    if(!cover && query.data.cover){
        coverToShow = query.data.cover;
    }
    let coverToShowSq = false;
    if(cover4){
        coverToShowSq = cover4;
    }
    if(!cover4 && query.data.cover_4){
        coverToShowSq = query.data.cover_4;
    }
    return (
        <div>


            <h2 style={{ marginTop: "50px" }}>
                {query.data.id != 0 ? 'Edit article' : 'Add article'}

                <Button size="md" variant="success" type="submit" onClick={save}>
                    Save article
                </Button>
            </h2>

            <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    value={query.data.title}
                    onChange={(ev) => {changeData('title', ev.target.value);}}
                    onBlur={generateRewrite}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Rewrite</Form.Label>
                <Form.Control
                    type="text"
                    value={query.data.rewrite}
                    onChange={(ev) => {changeData('rewrite', ev.target.value);}}
                    onBlur={generateRewrite}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Excerpt</Form.Label>
                <Form.Control
                    as="textarea"
                    value={query.data.excerpt}
                    onChange={(ev) => {changeData('excerpt', ev.target.value);}}
                />
            </Form.Group>


            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>Image</Form.Label>
                        <label style={{display: "block"}}>
                            <div>
                                {coverToShow ?
                                    <img style={{maxWidth: "100%", maxHeight: "300px"}} src={coverToShow} alt=""/>
                                    :null}
                            </div>

                            <div>
                                <input type="file" accept="image/*" name="cover" onChange={handleImageChange}/>
                            </div>
                        </label>

                    </Form.Group>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>Image square</Form.Label>
                        <label style={{display: "block"}}>
                            <div>
                                {coverToShowSq ?
                                    <img style={{maxWidth: "100%", maxHeight: "300px"}} src={coverToShowSq} alt=""/>
                                    :null}
                            </div>

                            <div>
                                <input type="file" accept="image/*" name="cover_4" onChange={handleImageChange}/>
                            </div>
                        </label>

                    </Form.Group>
                </div>
            </div>





            <Form.Group>
                <Form.Label>Content</Form.Label>
                <CKEditor
                    editor={ClassicEditor}
                    data={query.data.content}
                    onChange={(event, editor) => {
                        changeData('content', editor.getData());
                    }}
                />
            </Form.Group>
        </div>



    );

}
