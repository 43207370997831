import React from "react";
import {Row, Col, Button, Table, Image, Form} from "react-bootstrap";
import Request from "../api/Request";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { Trash } from 'react-bootstrap-icons';
import Nestable from "react-nestable-2";
const animatedComponents = makeAnimated();

class Nestable2 extends Nestable {
  onKeyDown = (e, item) => {

  };
}

export default class MenuEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      menu: [],
      custom_link: '',
      custom_label: ''
    };
  }

  componentDidMount() {
    Request.getMenuCategories((response) => {
      console.log(response);
      let categories = response.categories.map((category) => {
        let children = category.brands.map((brand) => {
          return {
            id: category.id+'-brand-'+brand.brandId,
            label: brand.brandName,
            link: "/brand/"+brand.uri,
            children: [],
            category: category.id,
            brand: brand.brandId,
            selected: false,
            expanded: false
          }
        })
        return {
          id: 'category-'+category.id,
          label: category.name,
          link: "/products/"+category.uri,
          children: children,
          category: category.id,
          brand: null,
          selected: false,
          expanded: false
        }
      });
      this.setState({categories: categories});
    });
    Request.getMenu((response) => {
      if(response.success){
        this.setState({menu: response.menu});
      }else{
        toast('Error on menu loading. Try again.')
      }
    });
  }

  uniqueKey() {
    return Math.round(Math.random() * 1000) + Date.now();
  }

  handleAddLink = (ev) => {
    ev.preventDefault();
    if(this.state.custom_link.trim() === '' || this.state.custom_label.trim() === ''){
      toast('Name and label are required');
      return;
    }

    this.addToMenu({
      label: this.state.custom_label,
      link: this.state.custom_link,
      children: [],
      category: null,
      brand: null,
      selected: false,
      expanded: false,
      id: this.uniqueKey(),
    });

    this.resetCustomLinkState();

  };

  handleAddCategory = (ev) => {
    ev.preventDefault();
    let selected = this.getSelectedCategories(JSON.parse(JSON.stringify(this.state.categories)));
    this.setState({
      menu: [...this.state.menu, ...selected],
      categories: this.deselectAllCategories(this.state.categories)
    });
    // this.
  }

  getSelectedCategories = (categories) => {
    let selected = [];
    categories.forEach((category) => {
      let children = category.children;
      if(category.selected){
        category.children = [];
        selected.push(category);
      }
      if(children){
        selected = [...selected, ...this.getSelectedCategories(children)];
      }
    });
    return selected;
  };

  deselectAllCategories = (categories) => {
    return categories.map((category) => {
      let children = category.children.map((brand) => {
        brand.selected = false;
        return brand;
      })
      category.selected = false;
      category.children = children;
      return category;
    });
  }

  resetCustomLinkState = () => {
    this.setState({
      custom_label: '',
      custom_link: ''
    });
  }

  addToMenu = (menuItem) => {
    let menuItems = this.state.menu;
    menuItems.push(menuItem);
    this.setState({menu: [...menuItems]})
  };

  deleteItem = (id_item) => {
    this.setState({menu: this.deleteItemRecursive(id_item, this.state.menu)})
  };
  deleteItemRecursive = (id_item, children) => {
    let filtered_items = children.filter((item) => {return item.id !== id_item});
    filtered_items.forEach((item) => {
      item.children = this.deleteItemRecursive(id_item, item.children);
    });
    return filtered_items;
  };

  handleChange = (items) => {
    this.setState({menu: items})
  };
  renderCollapseIcon = (isCollapsed) => {
    return (
        <button>{isCollapsed.isCollapsed ? '+' : '-'}</button>
    )
  };


  handleCategoryListCheckboxChange = (ev) => {

    let categories = this.state.categories;
    this.setState({categories: this.changeSelectedInCategoriesList(categories, ev.target.checked, ev.target.value)});
  };
  changeSelectedInCategoriesList = (list, state, item_id = null) => {
    list = list.map((item) => {
      if(item.id === item_id || item_id === null){
        item.selected = state;
      }
      if(item.children){
        item.children = this.changeSelectedInCategoriesList(item.children, state, item_id);
      }
      return item;
    });
    return list;
  };
  handleExpandedListCheckboxChange = (item_id) => {

    let categories = this.state.categories;
    this.setState({categories: this.changeExpandedInCategoriesList(categories, item_id)});
  };
  changeExpandedInCategoriesList = (list, item_id = null) => {
    list = list.map((item) => {
      if(item.id === item_id || item_id === null){
        item.expanded = !item.expanded;
      }
      if(item.children){
        item.children = this.changeExpandedInCategoriesList(item.children, item_id);
      }
      return item;
    });
    return list;
  };

  handleSave = (ev) => {
    ev.preventDefault();

    Request.saveMenu(this.state.menu, (response) => {
      if(response.success){
        toast('Saved')
      }else{
        toast('Failed');
      }
    });

  };


  renderItem = (data) => {
    return (
        <div className="MenuEdit__menu__item" key={data.item.id} id={data.item.id}>
          <div className="MenuEdit__menu__item__text">
            {data.collapseIcon}

            <span className="MenuEdit__menu__item__text__label">Label: {data.item.label}</span>
            <span className="MenuEdit__menu__item__text__link">Link: {data.item.link}</span>
          </div>
          <div className="MenuEdit__menu__item__actions">
            <button onClick={() => {this.deleteItem(data.item.id)}}>x</button>
          </div>
        </div>
    )
  };


  renderCategoryList = (list, depth = 0) => {
    return (
        <ul className="MenuEdit__categories" data-depth={depth}>
          {list.map(item => {
            return (
                <li className="MenuEdit__categories__item" key={item.id}>
                  <label>
                    <input type="checkbox" value={item.id} checked={item.selected} onChange={this.handleCategoryListCheckboxChange}/>
                    {item.label}
                    {item.children.length &&
                        <button onClick={(ev) => {ev.preventDefault(); this.handleExpandedListCheckboxChange(item.id)}}>{item.expanded ? '-' : '+'}</button>
                    }
                  </label>
                  {item.children && item.expanded ?
                      this.renderCategoryList(item.children, depth+1)
                      : <></>
                  }
                </li>
            )
          })}
        </ul>
    )
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Menu Edit</h2>

            <div className="MenuEdit">

              <div className="MenuEdit__links">

                <h3>Categories</h3>
                {this.renderCategoryList(this.state.categories, 0)}
                <Button onClick={this.handleAddCategory.bind(this)}>Add</Button>


                <h3>Custom Link</h3>

                <Form.Control
                    type="text"
                    value={this.state.custom_link}
                    onChange={(ev) => this.setState({custom_link: ev.target.value})}
                    placeholder="Link"
                />
                <Form.Control
                    type="text"
                    value={this.state.custom_label}
                    onChange={(ev) => this.setState({custom_label: ev.target.value})}
                    placeholder="Label"
                />
                <Button onClick={this.handleAddLink.bind(this)}>Add</Button>


              </div>
              <div className="MenuEdit__menu">
                <h3>Menu <Button onClick={this.handleSave.bind(this)}>Save</Button></h3>


                <Nestable2
                    items={this.state.menu}
                    renderItem={this.renderItem.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    onKeyDown={() => {
                    }}
                    maxDepth={2}
                    renderCollapseIcon={this.renderCollapseIcon.bind(this)}
                />


              </div>
            </div>

          </Col>
        </Row>
      </>
    );
  }
}
