import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Request from '../api/Request';
import { connect } from "react-redux";
import { userFetched } from "../actions";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: false,
        };
    }

    login(event) {
        event.preventDefault();
        this.setState({error: false});
        Request.login(this.state.email, this.state.password, (response) => {
            if (response.success) {
                this.props.userFetched(response.me);
                window.localStorage.setItem('loggedUser', JSON.stringify(response));

            } else {
                this.setState({error: true});
            }
        });
    }

    render() {
        return <Row style={{ paddingTop: '20px' }}>
            <Col sm={12} md={{ offset: 5, span: 2 }}>
                <Form onSubmit={this.login.bind(this)}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control onChange={event => this.setState({email: event.target.value})} value={this.state.email} type="email" placeholder="Email" />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Control onChange={event => this.setState({password: event.target.value})} value={this.state.password} type="password" placeholder="Password" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Login
                    </Button>
                </Form>
            </Col>
        </Row>;
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.user
    };
  };
  const mapDispatchToProps = { userFetched }; // (2)
  export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
