import React from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { List } from 'react-bootstrap-icons';
import { connect } from "react-redux";
import { isMobileFetched  } from "../../actions";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     showMenu: false,
    };
  }

  setShowMenu(){
    if(this.state.showMenu){
      this.setState({showMenu: false});
    }else{
      this.setState({showMenu: true});
    }
  }

  logout(){
    window.localStorage.removeItem('loggedUser');
    window.location.reload();
  }

  render() {
    const linkStyle = {
      color: '#fff',
      fontSize: '20px'
    };
    const selectedLinkStyle = {
      ...linkStyle,
      fontWeight: '600'
    };

    const showMenuClass = this.state.showMenu
    ? "mobileMenu"
    : "mobileMenu  mobileMenuHidden";

    if(this.props.isMobile){
      return(
        <div style={{marginTop: '10px'}}>
          <Nav>
            <Nav.Item>
             <img src="https://frayagroup.com/wp-content/uploads/2020/01/sygnet.png" />
            </Nav.Item>
            <div style={{position: 'absolute', right: '20px'}}><List color='#fff' size={57} onClick={this.setShowMenu.bind(this)}/></div>
            <div className={showMenuClass}>
              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-brand">Dashboard</NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/users">Users</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/purposes">Product Purposes</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/types">Product Types</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/products">Products</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/delivery-cost">Delivery Costs</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-producer">Producers</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-brand">Brands</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/orders">Orders</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/orders-b2c">Orders B2C</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink onClick={this.setShowMenu.bind(this)} exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/mail-settings">Mail Settings</NavLink>
              </Nav.Item>
            </div>
          </Nav>
        </div>

      )
    }else{
    return (
    <Nav defaultActiveKey="/users" className="flex-column">
      <Nav.Item style={{ textAlign: 'center', marginTop: '20px' }}>
        <img src="https://frayagroup.com/wp-content/uploads/2020/01/sygnet.png" />
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-brand">Dashboard</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/seo">SEO</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/users">Users</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/clients">Clients</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/purposes">Product Purposes</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/types">Product Types</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/products">Products</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/delivery-cost">Delivery Costs</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-producer">Producers</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-brand">Brands</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/orders">Orders</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/orders-b2c">Orders B2C</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/mail-settings">Mail Settings</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/bestsellers">Bestsellers</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/menu_edit">Menu Edit</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/banner_info">Banner info</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/articles">Articles</NavLink>
      </Nav.Item>

      <Nav.Item>
        <NavLink exact={true} onClick={this.logout.bind(this)} activeStyle={selectedLinkStyle} style={linkStyle} to="/logout">Logout</NavLink>
      </Nav.Item>

      {/* <Nav.Item>
        <NavLink exact={true} activeStyle={selectedLinkStyle} style={linkStyle} to="/add-brand">Mail Log</NavLink>
      </Nav.Item> */}

    </Nav >
    )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile
  };
};
const mapDispatchToProps = { isMobileFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
