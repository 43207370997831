import React from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ProductNameInput from "../addProduct/ProductNameInput";

export default class SingleDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favourite: 0,
      timeout: null,
      showEditor: false,
      meta_title: props.meta_title
    };
  }

  componentDidMount() {
    this.setState({ favourite: this.props.favourite })
  }

  // setFavouriteCategory() {
  //   this.setState((state) => {
  //     if(this.state.favourite){
  //       state.favourite = false;
  //     }else{
  //     state.favourite = true;
  //     }
  //     return state;
  //   }, ()=> this.props.setFavouriteBrand(this.state.favourite));
  // }

  render() {
    return (
      <>
        <tr>
          <td style={{ textAlign: 'center' }}><Form>
            <Form.Group style={{ position: 'relative', bottom: '-10px' }} controlId="formBasicCheckbox">
              <Form.Check onChange={this.props.setFavouriteBrand} checked={this.props.favourite} type="checkbox" />
            </Form.Group>
          </Form>
          </td>
          <td style={{ textAlign: 'center' }}><p style={{ margin: '0', paddingTop: '8px' }}>{this.props.name}</p></td>
          <td style={{ textAlign: 'center' }}><p style={{ margin: '0', paddingTop: '8px' }}>{this.props.count}</p></td>


          <td style={{ textAlign: 'center' }}>

            <Form.Group>
              <Form.Control as="select" value={this.props.producer} onChange={(event) => { this.props.changeProducer(event.target.value) }}>
                <option>Please select</option>
                {this.props.producers.map(producer => <option value={producer.id}>{producer.name}</option>)}
              </Form.Control>
            </Form.Group>
          </td>


          <td style={{ textAlign: 'center' }}>
            <Button size="sm" variant="danger"
              onClick={this.props.removeBrand}>
              Delete <Trash />
            </Button>
          </td>
        </tr>
        <tr>
          <td colspan='5'>
            {this.state.showEditor ?
            <>
              <CKEditor
                editor={ClassicEditor}
                data={this.props.description}
                onChange={(event, editor) => {
                  if (this.state.timeout) {
                    clearTimeout(this.state.timeout);
                  }
                  this.setState({
                    timeout: setTimeout(() => {
                      this.props.descriptionChange(editor.getData());
                    },
                      1000)
                  })

                }}
              />
              <h3 style={{width: '100%', height: 'max-content', fontSize: '18px', textAlign: 'center', background: '#73A1B3', color: '#ffffff'}}>Brand Meta Title</h3>
              <ProductNameInput
                  label="Brand Meta Title"
                  name={this.state.meta_title}
                  handleInputChange={(event) => {
                    const {value} = event.target;
                    if (this.state.timeout) {
                      clearTimeout(this.state.timeout);
                    }
                    this.setState({
                      timeout: setTimeout(() => {
                            this.props.metaTitleChange(value);
                          },
                          1000),
                      meta_title: value
                    })
                  }}
              />

              <h3 style={{width: '100%', height: 'max-content', fontSize: '18px', textAlign: 'center', background: '#73A1B3', color: '#ffffff'}}>Brand Meta Description</h3>
              <CKEditor
              editor={ClassicEditor}
              data={this.props.meta_description}
              onChange={(event, editor) => {
                if (this.state.timeout) {
                  clearTimeout(this.state.timeout);
                }
                this.setState({
                  timeout: setTimeout(() => {
                    this.props.metaDescriptionChange(editor.getData());
                  },
                    1000)
                })

              }}
            />
            </>
              :
              <>
                <div dangerouslySetInnerHTML={{__html: this.props.description}} />
                <h3 style={{width: '100%', height: 'max-content', fontSize: '18px', textAlign: 'center', background: '#73A1B3', color: '#ffffff'}}>Brand Meta Title</h3>
                <div dangerouslySetInnerHTML={{__html: this.props.meta_title}} />

                <h3 style={{width: '100%', height: 'max-content', fontSize: '18px', textAlign: 'center', background: '#73A1B3', color: '#ffffff'}}>Brand Meta Description</h3>
                <div dangerouslySetInnerHTML={{__html: this.props.meta_description}} />
                <Button variant="success" size="sm" onClick={() => { this.setState({ showEditor: true }) }}>
                  Show editor for {this.props.name}
                </Button>
              </>
            }
          </td>
        </tr>
      </>
    );
  }
}
