import React from "react";
import { Form, InputGroup } from "react-bootstrap";

export default class ProductPriceInput extends React.Component {
  render() {
    return (
      <div>
        <Form.Group controlId="priceUSD">
          <Form.Label>{this.props.label || 'Price EUR'}</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              step="0.01"
              min="0.00"
              value={this.props.eur}
              onChange={this.props.handleEURChange}
            />
          </InputGroup>
        </Form.Group>
      </div>
    );
  }
}
