import React from "react";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { productToEditFetched, isMobileFetched } from "../actions";
import Request from "../api/Request";
import {
  withRouter
} from 'react-router-dom';
import { toast } from 'react-toastify';
import SingleOrderB2C from "../components/orderList/SingleOrderB2C";

class OrdersB2C extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
    };
  }

  fetchData() {
    Request.getOrdersB2C(response => {
      if (response.success) {
        this.setState({ orders: response.orders });
      }
    });
  }

  componentDidMount() {
    this.fetchData();

   }

  addNew() {
    this.props.productToEditFetched({});
    this.props.history.push('/add-product');
  }

  setProductToEdit(id){
    this.props.productToEditFetched(id);
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Orders B2C</h2>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Order</th>
                  <th style={{textAlign: 'center'}}>Client</th>
                  <th style={{textAlign: 'center'}}>Date</th>
                  <th style={{textAlign: 'center'}}>Status</th>
                  <th style={{textAlign: 'center'}}>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.orders ? this.state.orders.filter(order => order.delivery.name !=  null && order.delivery.name != '').map((order, id) => (
                  <SingleOrderB2C
                    key={id}
                    order={order}
                  />
                )) : <></>}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { productToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersB2C));
