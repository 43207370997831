import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { PersonFill } from "react-bootstrap-icons";
import SingleUser from "../components/userList/SingleUser";
import Request from "../api/Request";
import { userToEditFetched,  isMobileFetched  } from "../actions";
import {
  withRouter
} from 'react-router-dom'
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import SingleClient from "../components/userList/SingleClient";

class ClientsListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  fetchData() {
    Request.getClients(response => {
      if (response.success) {
        this.setState({ users: response.users.reverse() });
      }
    });
  }

  componentDidMount() {
   this.fetchData();
  }
  deleteUser(user) {
    if (window.confirm('Delete (' + user.email + ') client?')) {
      Request.deleteClient({id: user.id}, (response) => {
        toast(response.success ? 'Client deleted successfully!': 'There was an error.');
        if (response.success) {
          this.fetchData();
        }
      })
    };
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ marginTop: "50px" }}>Clients List ({this.state.users.length})</h2>
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>#</th>
                  <th style={{textAlign: 'center'}}>Name</th>
                  {this.props.isMobile ? <></> : <th style={{textAlign: 'center'}}>Email</th> }
                  <th style={{textAlign: 'center'}}>Company</th>
                  <th style={{textAlign: 'center'}}>VAT number</th>
                  <th style={{textAlign: 'center'}}>Phone</th>
                  <th style={{textAlign: 'center'}}>Country</th>
                  <th> </th>
                  <th> </th>
                  {/*{this.props.isMobile ? <></> :<th style={{textAlign: 'center'}}>Postal Code</th>}*/}
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((user, id) => <SingleClient deleteUserCallback={() => this.deleteUser(user)} user={user} index={id+1} key={id} />)}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile
  };
};
const mapDispatchToProps = { userToEditFetched, isMobileFetched  }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientsListPage));
