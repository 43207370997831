import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { CheckCircle } from "react-bootstrap-icons";

export default class NewsletterSelect extends React.Component {
  render() {
    return (
      <Form.Group controlId="userCountry">
        <Form.Label>Newsletter</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
            <CheckCircle />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            value={this.props.newsletter}
            onChange={this.props.handleInputChange}
          >
            <option value={true}>YES</option>
            <option value={false}>NO</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>
    );
  }
}
