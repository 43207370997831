import { combineReducers } from "redux";
import { productToEdit } from "./productToEdit";
import { user } from "./user";
import { userToEdit } from "./userToEdit";
import { orderToEdit } from "./orderToEdit";
import { isMobile } from "./isMobile";

export default combineReducers({
    productToEdit,
    user,
    userToEdit,
    orderToEdit,
    isMobile,
});