import React from "react";
import { Button } from "react-bootstrap";
import { Trash } from 'react-bootstrap-icons';
import { userToEditFetched , isMobileFetched} from "../../actions";
import {
    Link,
    withRouter
} from 'react-router-dom'
import { connect } from "react-redux";

class SingleUser extends React.Component {
  selectUser() {
    // this.props.userToEditFetched(this.props.user);
    // this.props.history.push('/add-user');
  }

  render() {
    const tdStyle = {
      textAlign: 'center'
    };
    const pStyle = {
      margin: '0',
      paddingTop: '8px',
      cursor: 'pointer'
    }
    return (
      <tr>
        <td style={tdStyle} title={this.props.user.id}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.index}</p></td>
        {/*<td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.id}</p></td>*/}
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.name} {this.props.user.surname}</p></td>
        {this.props.isMobile ? <></> : <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.email}</p></td>}
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.company_name}</p></td>
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.vat_number}</p></td>
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.country_number} {this.props.user.phone_number}</p></td>
        <td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.country}</p></td>
          <td style={tdStyle}>
              <Link to={"/client/"+this.props.user.id} className="btn btn-info btn-sm">Details</Link>
          </td>
          <td style={tdStyle}>
              <Button size="sm" variant="danger"
                      onClick={this.props.deleteUserCallback}
                      style={{whiteSpace: "nowrap"}}
              >
                  Delete <Trash />
              </Button>
          </td>

        {/*{this.props.isMobile ? <></> :<td style={tdStyle}><p onClick={this.selectUser.bind(this)} style={pStyle}>{this.props.user.post_code}</p></td>}*/}
      </tr>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    userToEdit: state.userToEdit,
    isMobile: state.isMobile,
  };
};
const mapDispatchToProps = { userToEditFetched, isMobileFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleUser));
